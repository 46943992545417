import axios from 'axios';
import React from "react";

import { CustomerFormComponent } from '../../components'
import { Container } from 'react-bootstrap'
import NotificationModal from "../../components/NotificationModal";
import useModal from "../../utils/useModal";

import * as Constants from '../../utils/constants';
import { Helmet } from 'react-helmet';

const CustomerCreate = function (props) {

    const { data: notificationData, setData: setNotificationData } = useModal();

    const defaultData = {
        name: "",
        notificationLevel: "30",
        comment: ""
    }
    const saveHandler = (formData) => {
        axios.post(Constants.API_BASE + 'api/customer', formData,)
            .then(res => {

                props.history.push({
                    pathname: '/customers',
                    state: { toast: `Created new customer: ${formData.name}.` }
                });

            }).catch(err => setNotificationData({ title: "Update Failed", text: err.message }))
    }

    return (
        <Container className="mt-3">
            <Helmet>
                <title>Create Customer - Anubis</title>
            </Helmet>
            <NotificationModal data={notificationData} />
            <CustomerFormComponent default={defaultData} saveHandler={saveHandler} />
        </Container>
    );
};

export default CustomerCreate;
