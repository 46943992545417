import React, { useEffect } from 'react';
import { Table, Form, Alert } from 'react-bootstrap';

const SetupAssignment = ({ systemIds, baseTypes, setupTypes, scaleTypes, rampTypes, assignments, setAssignments, error, setError, totalBaseSystems }) => {

  useEffect(() => {
    if (systemIds.length !== totalBaseSystems) {
      setError(`The number of system IDs does not match the number of base types. Specified base systems ${totalBaseSystems}. System IDs: ${systemIds.length} `);
    }
  }, [systemIds, baseTypes, setError, totalBaseSystems]);

  const handleTypeChange = (index, value, type) => {
    const newAssignments = [...assignments];
    newAssignments[index][type] = value;
    setAssignments(newAssignments);

    if (newAssignments.every(assignment => assignment.setupType && assignment.scaleType && assignment.rampType)) {
      setError('');
    } else {
      setError('Not all types have been assigned.');
    }
  };

  const countOccurrences = (array, value) => {
    return array.filter(item => item === value).length;
  };

  const isTypeAssigned = (typeArray, type, currentIndex, typeName) => {
    const currentAssignmentCount = assignments.filter(assignment => assignment[typeName] === type).length;
    const initialTypeCount = countOccurrences(typeArray, type);
    return currentAssignmentCount >= initialTypeCount && assignments[currentIndex][typeName] !== type;
  };

  return (
    <div>
      {error && <Alert variant="danger">{error}</Alert>}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>System ID</th>
            <th>Base Type</th>
            <th>Setup Type</th>
            <th>Scale Type</th>
            <th>Ramp Type</th>
          </tr>
        </thead>
        <tbody>
          {assignments.map((assignment, index) => (
            <tr key={assignment.systemId}>
              <td>{assignment.systemId}</td>
              <td>{assignment.baseType}</td>
              <td>
                <Form.Control
                  as="select"
                  value={assignment.setupType}
                  onChange={(e) => handleTypeChange(index, e.target.value, 'setupType')}
                >
                  <option value="">Select Setup Type</option>
                  {setupTypes.map((setupType, idx) => (
                    <option
                      key={idx}
                      value={setupType}
                      disabled={isTypeAssigned(setupTypes, setupType, index, 'setupType')}
                    >
                      {setupType}
                    </option>
                  ))}
                </Form.Control>
              </td>
              <td>
                <Form.Control
                  as="select"
                  value={assignment.scaleType}
                  onChange={(e) => handleTypeChange(index, e.target.value, 'scaleType')}
                >
                  <option value="">Select Scale Type</option>
                  {scaleTypes.map((scaleType, idx) => (
                    <option
                      key={idx}
                      value={scaleType}
                      disabled={isTypeAssigned(scaleTypes, scaleType, index, 'scaleType')}
                    >
                      {scaleType}
                    </option>
                  ))}
                </Form.Control>
              </td>
              <td>
                <Form.Control
                  as="select"
                  value={assignment.rampType}
                  onChange={(e) => handleTypeChange(index, e.target.value, 'rampType')}
                >
                  <option value="">Select Ramp Type</option>
                  {rampTypes.map((rampType, idx) => (
                    <option
                      key={idx}
                      value={rampType}
                      disabled={isTypeAssigned(rampTypes, rampType, index, 'rampType')}
                    >
                      {rampType}
                    </option>
                  ))}
                </Form.Control>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default SetupAssignment;