import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMessage, faCircleQuestion, faCircleStop } from '@fortawesome/free-regular-svg-icons'
import { faHashtag, faBomb, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

export function StringFromInt(level) {

    const l = parseInt(level);

    if(l === 0) return "DEFAULT";
    if(l === 10) return "DEBUG";
    if(l === 20) return "INFO";
    if(l === 30) return "WARNING";
    if(l === 40) return "ERROR";
    if(l === 50) return "FATAL";
    if(l === 99) return "NONE";

    return "UNKNOWN";
}

export function IconFromInt(level) {

    const l = parseInt(level);

    if(l === 0) return (<OverlayTrigger overlay={<Tooltip>Default</Tooltip>}><FontAwesomeIcon fontSize="16" icon={faHashtag} style={{color: "#000"}}/></OverlayTrigger>);
    if(l === 10) return (<OverlayTrigger overlay={<Tooltip>Debug</Tooltip>}><FontAwesomeIcon fontSize="16" icon={faMessage} style={{color: "#000"}}/></OverlayTrigger>);
    if(l === 20) return (<OverlayTrigger overlay={<Tooltip>Info</Tooltip>}><FontAwesomeIcon fontSize="16" icon={faCircleQuestion} style={{color: "#459fba"}}/></OverlayTrigger>);
    if(l === 30) return (<OverlayTrigger overlay={<Tooltip>Warning</Tooltip>}><FontAwesomeIcon fontSize="16" icon={faCircleStop} style={{color: "#ffb70f"}}/></OverlayTrigger>);
    if(l === 40) return (<OverlayTrigger overlay={<Tooltip>Error</Tooltip>}><FontAwesomeIcon fontSize="16" icon={faTriangleExclamation} style={{color: "#ff0000"}}/></OverlayTrigger>);
    if(l === 50) return (<OverlayTrigger overlay={<Tooltip>Fatal</Tooltip>}><FontAwesomeIcon fontSize="16" icon={faBomb} style={{color: "#ff0000"}}/></OverlayTrigger>);
    if(l === 99) return (<OverlayTrigger overlay={<Tooltip>NONE</Tooltip>}><FontAwesomeIcon fontSize="16" icon={faHashtag} style={{color: "#ff0000"}}/></OverlayTrigger>);;

    return "UNKNOWN";
}

export function GetEffectiveLogLevelString(level, dbGate) {
    
    let gateLevel = parseInt(dbGate.notificationLevel);
    let customerLevel = parseInt(dbGate.Customer.notificationLevel);
    
    if(gateLevel === 0) {
        // If there is no gate level set for this gate, use the customer level
        return StringFromInt(customerLevel) + "*";
    } else {
        // If there is a specific gate level, use the gate level
        return StringFromInt(gateLevel);
    }

}