import React from "react";
import axios from 'axios';
import { Container, Row, Col, Form } from "react-bootstrap"

import SystemUsageChart from "../components/graphs/systemUsageChart";

import * as Constants from '../utils/constants';
import SimpleBarChart from "../components/graphs/simpleBarChart";
import LocationUsageChart from "../components/graphs/locationUsageChart"
import { useKeycloak } from '../utils/keycloak-hook'



const Usage = function (props) {

  const [plotData, setPlotData] = React.useState({ scanCounts: null, binnedScans: null, hourlyThroughput: null, locationCounts: null });

  const [locationUsage, setLocationUsage] = React.useState(null);
  const [locationUsageUpdating, setLocationUsageUpdating] = React.useState(false);

  const { token } = useKeycloak()

  const query = React.useCallback(() => {

    const CancelToken = axios.CancelToken;
    const cancelToken1 = CancelToken.source()

    const usageDataPromise = axios.get(Constants.API_BASE + 'api/statistics/usageData', { cancelToken: cancelToken1.token });

    Promise.allSettled([usageDataPromise])
      .then((results) => {
        const usageData = results[0];

        let newPlotData = null;

        if (usageData.status === "fulfilled") {
          newPlotData = usageData.value.data;
        } else {
          console.error(usageData.reason);
        }

        setPlotData(newPlotData);
      })

    return () => {
      cancelToken1.cancel("effect cleanup. cancel token 1")
    }
  }, []);

  // Use effect to query for data
  React.useEffect(() => {
    query();
  }, [query])

  // Locations list for SELECTs
  let locations = []



  // Augment location data
  const locationMap = new Map();
  if (plotData.scanCounts !== null) {

    plotData.locationCounts.forEach(v => {
      locationMap.set(v.id, { name: v.name, cnt: v.cnt, N: 0, sys0: 0, sys1: 0, sys2: 0, sys3: 0 })
      locations.push({ id: v.id, name: v.name })
    });

    plotData.scanCounts.forEach(v => {
      // If location has not been set -> skip
      if (v.LocationId === null) return;

      // get the location object via its lection id
      const d = locationMap.get(v.LocationId);

      // dynamically set cnt value of next system id
      d['sys' + d.N] = v.cnt;
      d.N += 1
    })
  }

  // Convert map to array to make recharts happy
  const locationArray = Array.from(locationMap, ([name, val]) => val);
  locations.sort((a, b) => a.name.localeCompare(b.name))

  // Start 24h ago, floor to the full hour
  let start = new Date();
  start.setDate(start.getDate() - 1);
  const minTimestamp = Math.floor(start.getTime() / 1000 / 3600) * 3600;

  let sumLast24 = 0
  let sumLastWeek = 0
  console.log(plotData)
  if (plotData.binnedScans != null) {
    for (let i = 0; i < plotData.binnedScans.length; i++) {

      // Sum last 24h
      if (plotData.binnedScans[i].block >= minTimestamp) {
        sumLast24 += plotData.binnedScans[i].cnt;
      }

      // Sum complete week
      sumLastWeek += plotData.binnedScans[i].cnt;
    }
  }

  // Get throughput gauge panel variables
  let maxThroughput = 0;
  let maxThroughputName = "?";
  if (plotData.hourlyThroughput != null) {
    maxThroughputName = !!(plotData.hourlyThroughput[0]) ? plotData.hourlyThroughput[0].gateIdString : 'unknown';
    maxThroughput = !!(plotData.hourlyThroughput[0]) ? (plotData.hourlyThroughput[0].cnt).toFixed(1) : 0;
  }

  // Get active gauge panel variables
  let activeCount = "?";
  if (plotData.scanCounts != null) {
    activeCount = plotData.scanCounts.length;
  }

  const updateLocationUsage = (e) => {
    const id = e.target.value;
    if (id === 0) {
      setLocationUsage(null);
    }
    else {
      setLocationUsageUpdating(true)
      // query & stuff
      
        const headers = { Authorization: 'Bearer ' + token }
        axios.get(Constants.API_BASE + 'api/statistics/usageCountsPerLocation/' + id, { headers: headers })
          .then((results) => {
            setLocationUsage(results.data)
            setLocationUsageUpdating(false)
          })
          .catch(err => alert(err))
    }
  }

  return (
    <Container fluid style={{ marginTop: "8px", marginBottom: "8px" }}>
      <Row >
        <Col xs={12} className="panel">
          <h3>Hourly Global System Usage</h3>
          <SystemUsageChart data={plotData.binnedScans} secondData={locationUsage} height={350} />
          <Form className="flex-row-reverse">
            <Form.Group>
              <Form.Label>Overlay Location Usage:</Form.Label>{' '}
              <Form.Control as="select" size="sm" onChange={(e) => updateLocationUsage(e)} disabled={locationUsageUpdating}>
                <option value="0">none</option>
                {locations.map(v => {
                  return <option value={v.id} key={"location-" + v.id}>{v.name}</option>
                })}
              </Form.Control>
            </Form.Group>
          </Form>
        </Col>
      </Row>

      <Row>
        <Col className="panel panel-gauge text-center">
          <p>Last 24H</p>
          <h2>{sumLast24}</h2>
          <p>Total Measurements</p>
        </Col>

        <Col className="panel panel-gauge text-center">
          <p>Last 7 Days</p>
          <h2>{sumLastWeek}</h2>
          <p>Total Measurements</p>
        </Col>

        <Col className="panel panel-gauge text-center">
          <p>{maxThroughputName}</p>
          <h2>{maxThroughput} <sup>1</sup>/<sub>hour</sub></h2>
          <p>Highest hourly throughput in past 24h</p>
        </Col>

        <Col className="panel panel-gauge text-center">
          <p>Active Systems</p>
          <h2>{activeCount}</h2>
          <p>In the past 7 days</p>
        </Col>
      </Row>

      <Row>
        <Col className="panel" xs={12}>
          <h3>Weekly Usage by Location</h3>
          <LocationUsageChart data={locationArray} xlabeloffsety={40} height={450} ylabel="Number of Measurements" ylabeloffset={0} xkey="name" />
        </Col>
      </Row>

      <Row>
        <Col className="panel" xs={6}>
          <h3>Most used systems in the last 7 days</h3>
          <SimpleBarChart data={plotData.scanCounts} height={350} ylabel="Number of Measurements" ylabeloffset={0} top={12} />
        </Col>

        <Col className="panel" xs={6}>
          <h3>Least used systems in the last 7 days</h3>
          <SimpleBarChart data={plotData.scanCounts} height={350} ylabel="Number of Measurements" ylabeloffset={0} flop={12} />
        </Col>
      </Row>

      <Row>
        <Col className="panel">
          <h3>Hourly Throughput per System in the last 24h</h3>
          <SimpleBarChart data={plotData.hourlyThroughput} height={350} ylabel="Max. Measurements Within 1 Hour" ylabeloffset={20} />
        </Col>
      </Row>

    </Container>
  );
}
export default Usage;
