import React from "react";
import GoogleChart from "./googleChart";
import * as Constants from '../../utils/constants';

const ToGoogleChartFormat = (g) => {
  const res = [];
  res.push(["Year-Week", "Cases", "Effort"])
  for(let i=0; i<g.length; i++) {

    res.push([
      g[i].YEARWEEK,
      g[i].cases,
      g[i].totalEffort,
    ])
  }
  return res;
}

const MaintenanceEffortByWeek = (props) => {

  const data = ToGoogleChartFormat(props.data);

  if(data.length > 1) {
  } else {
    return (<p style={{textAlign: "center", marginTop: 20}}>No non-measurement events occured in the last 7 days</p>)
  }

  const gchartOptions = {
      backgroundColor: "transparent",
      legend: {
          position: "bottom"
      },
      series: {
        0: {targetAxisIndex: 0},
        1: {targetAxisIndex: 1}
      },
      vAxes: {
        // Adds titles to each axis.
        0: {title: 'Support Cases', titleTextStyle: {italic: false}},
        1: {title: 'Total Effort in Minutes', titleTextStyle: {italic: false}}
      },
      vAxis: {
        ticks: [],
        minValue: 0.5,
        maxValue: 8.5,
        
      },
      chartArea:{width:'95%',height:'75%', top: 10, bottom: 42, backgroundColor: "transparent"},
      fontSize: 12,
      fontName: "'Segoe UI', Roboto",
      colors: Constants.PLOT_COLORS
    };

  return (
      <GoogleChart
          chartType="ColumnChart"
          width={props.width}
          height={props.height}
          data={data}
          options={gchartOptions}
        />
  )
}

MaintenanceEffortByWeek.defaultProps = {
    height: "380px",
    width: "100%",
}

export default MaintenanceEffortByWeek;