import axios from 'axios';
import React from "react";

import { Container } from 'react-bootstrap'

import * as Constants from '../../utils/constants';
import XpuFormComponent from '../../components/xpuFormComponent';

import NotificationModal from "../../components/NotificationModal";
import useModal from "../../utils/useModal";

const XpuEdit = function (props) {

    const xpuId = props?.match?.params?.id;
    const {data: notificationData, setData: setNotificationData} = useModal()

    const saveHandler = (formData) => {

        axios.put(Constants.API_BASE + 'api/xpu/'+xpuId, formData,)
            .then(res => {
                console.log(res);
                props.history.push({
                    pathname: '/xpus',
                    state: { toast: `Updated xpu: ${formData.name}.` }
                });
            }).catch(err => setNotificationData({title: "Update Failed", text: err.message}))
    }

    return (
        <Container className="mt-3">
            <NotificationModal data={notificationData} />
            <h2 className="text-center">Edit XPU</h2>
            <XpuFormComponent saveHandler={saveHandler} create={false} xpuId={xpuId} />
        </Container>
    );
};

export default XpuEdit;
