import { useKeycloak } from '../utils/keycloak-hook'
import React from "react";
import { Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const SubNavigation = function (props) {

    const location = useLocation()

    const { keycloak } = useKeycloak();
    const userRoles = keycloak.resourceAccess["logserver-backend"].roles;

    let jsx = []

    for (let i = 0; i < props.pages.length; i++) {

        if (props.pages[i].role !== undefined) {
            // Skip if user does not required roles            
            if (!userRoles.includes(props.pages[i].role))
                continue;
        }

        jsx.push(<Link to={props.pages[i].key} key={props.pages[i].key + "-" + i} >
            <Button variant={location.pathname === props.pages[i].key ? "secondary" : "light"} >{props.pages[i].title}</Button>
        </Link>)
    }

    return (
        <div style={styles.container}>
            <span style={styles.title}>{props.title}</span>
            {jsx}
        </div>
    )
};

const styles = {
    container: {
        backgroundColor: "#b3b8b9",
        paddingTop: 2,
        paddingBottom: 4,
        flex: 1,
        display: "flex",
        gap: 4,
        alignContent: 'center',
        justifyContent: 'left',
    },
    title: {
        paddingLeft: 10,
        paddingTop: 5,
        paddingRight: 6,
    }

}

SubNavigation.defaultProps = {
    title: "SubNavigation",
    view: null,
    setView: () => { },
    pages: []
}

export default SubNavigation;
