import React from "react";
import { Container, Row, Col } from "react-bootstrap"
import { LoadingWithLogo } from "../components";
import { useKeycloak } from '../utils/keycloak-hook'

const Profile = () => {
  const { profile, keycloak } = useKeycloak();

  if (profile === null) return <LoadingWithLogo />

  console.log(profile)
  console.log(keycloak.resourceAccess["logserver-backend"].roles)

  return (
    <Container className="mt-3" fluid>
      <Row>
        <Col>
          <h2>{profile.name}</h2>
          <p>{profile.email}</p>
        </Col>
      </Row>
    </Container>
  )
};

export default Profile;
