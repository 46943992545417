import React from "react";
import { Modal, Button, Form, Alert, Col, Row } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import axios from 'axios';
import NotificationModal from "./NotificationModal";
import useModal from "../utils/useModal";

import * as Constants from '../utils/constants';

function EditPersonModal(props) {

    const { register, handleSubmit, errors, reset } = useForm({ defaultValues: { name: props.data?.name } })
    const [loading, setLoading] = React.useState(false);
    const {data: notificationData, setData: setNotificationData} = useModal();

    const resetAndClose = () => {
        setLoading(false)
        reset();
        props.onChange();
        props.onHide()
    }

    const onCancel = () => {
        resetAndClose();
    }

    const onSubmit = async formData => {

        setLoading(true)
        try {
            await axios.put(Constants.API_BASE + 'api/contact/' + props.data?.id, formData)
            resetAndClose();
        } catch (err) {
            setNotificationData({title: "Update Failed", text: err.message});
            setLoading(false);
        }
    }

    return (
        <>
            <NotificationModal data={notificationData} />
            <Modal
                show={props.show}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
            >
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Body>
                        <h3 className="text-center mt-4">Edit Person</h3>

                        <Alert>Changes made to this contact affect all instances where this contact is used.</Alert>
                        <Row>
                            <Col>

                                <Form.Group className="mb-2">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" name="name" placeholder="Name" ref={register({ required: true })} isInvalid={errors?.name} defaultValue={props.data?.name} />
                                    <Form.Control.Feedback type="invalid">Please enter a name</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-2">
                                    <Form.Label>E-Mail</Form.Label>
                                    <Form.Control type="text" name="email" placeholder="E-mail" ref={register({ required: true })} isInvalid={errors?.email} defaultValue={props.data?.email} />
                                    <Form.Control.Feedback type="invalid">Please enter an e-mail</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-2">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control type="text" name="phone" placeholder="Phone number" ref={register({ required: false })} isInvalid={errors?.phone} defaultValue={props.data?.phone} />
                                    <Form.Control.Feedback type="invalid">Please enter a phone number</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Description / Responsibility</Form.Label>
                                    <Form.Control type="text" name="description" placeholder="Description" ref={register({ required: true })} isInvalid={errors?.description} defaultValue={props.data?.description} />
                                    <Form.Control.Feedback type="invalid">Some error occured with the description</Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group className="mb-2">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control type="text" name="address" placeholder="Quantification Avenue 42" ref={register()} isInvalid={errors?.address} defaultValue={props.data?.address} />
                                </Form.Group>

                                <Form.Group className="mb-2">
                                    <Form.Label>Adress 2</Form.Label>
                                    <Form.Control type="text" name="address2" placeholder="optional" ref={register()} isInvalid={errors?.address2} defaultValue={props.data?.address2} />
                                </Form.Group>

                                <Form.Group className="mb-2">
                                    <Form.Label>Zip Code</Form.Label>
                                    <Form.Control type="text" name="zip" placeholder="12345" ref={register()} isInvalid={errors?.zip} defaultValue={props.data?.zip} />
                                </Form.Group>

                                <Form.Group className="mb-2">
                                    <Form.Label>City</Form.Label>
                                    <Form.Control type="text" name="city" placeholder="Measurementville" ref={register()} isInvalid={errors?.city} defaultValue={props.data?.city} />
                                </Form.Group>

                                <Form.Group className="mb-2">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Control type="text" name="country" placeholder="Palletopia" ref={register()} isInvalid={errors?.country} defaultValue={props.data?.country} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="float-end" onClick={() => onCancel()} disabled={loading}>
                            Cancel
                        </Button>
                        <Button className="float-end me-2" type="submit" disabled={loading}>
                            {loading ? 'Loading...' : 'Save'}
                        </Button>


                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

EditPersonModal.defaultProps = {
    customer: Constants.DEFAULT_CUSTOMER,
    id: -1,
    target: "location",
    onChange: () => { }
}

export default EditPersonModal;