import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import { BrowserRouter as Router } from "react-router-dom";
import {Helmet} from "react-helmet";

import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.css";

ReactDOM.render(
  <Router>
    <Helmet>
      <title>Anubis</title>
    </Helmet>
      <App />
  </Router>,
  document.getElementById("root")
);
