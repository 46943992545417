import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from "react-router-dom";

import VersionPieChart from "../graphs/versionPieChart";

const MaintenanceRemoteVersions = (props) => {

  const plotData = props.data


  console.log(plotData.versionsOverview)
  
  const customerDict = {};
  const MetrixDict = {};
  const UpdateTaskDict = {};
  const SensorServiceDict = {};
  const AnubisDict = {};

  plotData.versionsOverview.forEach(x => {
    
    if(x.versionMetrix === null) x.versionMetrix = "none";
    if(x.versionUpdateTask === null) x.versionUpdateTask = "none";
    if(x.versionSensorService === null) x.versionSensorService = "none";
    if(x.versionAnubis === null) x.versionAnubis = "none";

    customerDict[x.customerName] = null
    MetrixDict[x.versionMetrix] = null
    UpdateTaskDict[x.versionUpdateTask] = null
    SensorServiceDict[x.versionSensorService] = null
    AnubisDict[x.versionAnubis] = null
  });

  const customerNames = Object.keys(customerDict).sort().map(i => { return {label: i, value: i}})
  const metrixVersions = Object.keys(MetrixDict).sort().map(i => { return {label: i, value: i}})
  const updateTaskVersions = Object.keys(UpdateTaskDict).sort().map(i => { return {label: i, value: i}})
  const sensorServiceVersions = Object.keys(SensorServiceDict).sort().map(i => { return {label: i, value: i}})
  const anubisVersions = Object.keys(AnubisDict).sort().map(i => { return {label: i, value: i}})

  const columns = [
    {
      dataField: 'gateId',
      text: "System",
      formatter: (cell, row) => { return <Link to={"/systems/show/" + row.id}>{cell}</Link> },
    }, {
      dataField: 'customerName',
      text: '',
      formatter: (cell, row) => { return <Link to={"/customers/edit/" + row.customerId}>{cell}</Link> },
      filter: selectFilter({placeholder: "Customer", options: plotData == null ? [] : customerNames})
    }, {
      dataField: 'versionMetrix',
      filter: selectFilter({placeholder: 'MetriX', options: plotData == null ? [] : metrixVersions})
    }, {
      dataField: 'versionUpdateTask',
      text: "",
      filter: selectFilter({placeholder: 'UpdateTask', options: plotData == null ? [] : updateTaskVersions})
    }, {
      dataField: 'versionSensorService',
      text: "",
      filter: selectFilter({placeholder: 'SensorService', options: plotData == null ? [] : sensorServiceVersions})
    }, {
      dataField: 'versionAnubis',
      text: "",
      sort: true,
      filter: selectFilter({placeholder: 'Anubis', options: plotData == null ? [] : anubisVersions})
    }
  ];

  return (

      <Container fluid className="mb-5">

        <Row>
        <Col xs={12} sm={6} className="panel">
            <h3>Deployed MetriX Versions</h3>
            <VersionPieChart dataAll={plotData?.softwareVersions.versionsMetrix} data30={plotData?.softwareVersions.versionsMetrix90} />
          </Col>
          <Col xs={12} sm={6} className="panel">
            <h3>Deployed Anubis Versions</h3>
            <VersionPieChart dataAll={plotData?.softwareVersions.versionsAnubis} data30={plotData?.softwareVersions.versionsAnubis90} xkey="versionAnubis" />
          </Col>
        </Row>

        <Row>
          <Col className="panel">
            <h3>Versions by System</h3>
            <p style={{paddingLeft: 4}}>The table lists the versions for all systems that have the <b>active</b> flag enabled, and which have reported in at least once in the last 30 days.</p>
            <BootstrapTable
              keyField="id"
              data={plotData.versionsOverview}
              columns={columns}
              filter={filterFactory()}
              classes="log-table"
              pagination={paginationFactory({ hideSizePerPage: true, sizePerPage: 15 })}
              condensed
              bordered={false}
            />
          </Col>
        </Row>
        
      </Container>
  );
};

export default MaintenanceRemoteVersions;
