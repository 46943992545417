
export let API_BASE = "/";

export let DEFAULT_CUSTOMER = 1;
export let DEFAULT_LOCATION = 1;

//export const PLOT_COLORS = ['#9e0142', '#d53e4f', '#f46d43', '#fdae61', '#fee08b', '#e6f598', '#abdda4', '#66c2a5', '#3288bd', '#5e4fa2']
export const PLOT_COLORS = ['#184e77', '#1a759f', '#34a0a4', '#76c893', '#99d98c', '#1e6091', '#168aad', '#52b69a', '#99d98c']

//export const PLOT_COLORS = ['#99B4BC', '#898488', '#795353', '#b2b79f', '#AB9F82', '#E3D3DE']
export const CONTRAST_COLORS = ['#0057E9', '#87E911', '#E11845', '#8931EF', '#F2CA19', '#FF00BD']

export const COLORS = {
    ERROR: "#D00000",
    WARNING: "#cea217",
    INFO: "#1888e1",
    FONT: '#212529',
    FONT_HIGHLIGHT: '#d7692f',
}

export const Map_LogTypeNumeric_LogTypeString = {
    1: "metrix",
    2: "system",
    3: "camera",
    4: "scale",
    5: "barcode",
    6: "config",
    7: "general"
}

export const Map_LogTypeNumeric_Color = {
    1: "#66bb6a",
    2: "#1e88e5",
    3: "#f4511e",
    4: "#E043F2",
    5: "#9628EF",
    6: "#0007ED",
    7: "#000000"
}

export const Map_LogTypeString_LogTypeNumeric = {
    "metrix" : 1,
    "system": 2,
    "camera": 3,
    "scale": 4,
    "barcode": 5,
    "config": 6,
    "general": 7
}

export const Map_TimetrackTypeNumeric_TimetrackTypeString = {
    1: "Initial Setup",
    2: "Weekly Calls / Project Management",
    3: "Consulting (for existing Systems)",
    4: "Pre Sales",
    9: "Other",
}
