import React from "react";
import { Modal, Button } from 'react-bootstrap'



function BinaryDescisionModal(props) {

    return (

        <Modal show={props.show} size="md" aria-labelledby="contained-modal-title-vcenter" backdrop="static"        >
            <Modal.Body>
                <h3 className="text-center">{props.title}</h3>
                <p className="text-center mb-0">{props.text}</p>
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
                <Button onClick={() => props.no()}>No</Button>
                <Button onClick={() => props.yes()}>Yes</Button>
            </Modal.Footer>
        </Modal>
    );
}

BinaryDescisionModal.defaultProps = {
    yes: () => console.log("selected yes"),
    no: () => console.log("selected no"),
    title: "Modal Title",
    text: "Some text",
    show: false,
}

export default BinaryDescisionModal;