import React from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import { Col, Row, Button, Container, Alert } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';

import * as LogLevels from '../../utils/logLevels';
import * as Constants from '../../utils/constants';
import { Helmet } from "react-helmet";

const CustomerList = (props) => {
    const [data, setData] = React.useState(null)
    const [toastDismissed, setToastDismissed] = React.useState(false)

    let toast = "";
    if (!!props.location.state?.toast && toastDismissed === false) {
        toast = (<Alert variant="success" onClose={() => setToastDismissed(true)} dismissible>{props.location.state.toast}</Alert>)
    }

    React.useEffect(() => {
        axios.get(Constants.API_BASE + 'api/customer')
            .then(res => {
                setData(res.data);
            })
    }, [])

    const editButton = function (cell, row, rowIndex) {
        return (
            <>
                <Button as={Link} to={"/customers/edit/" + row.id} size="sm" className="me-1">
                    Edit
                </Button>
                <Button as={Link} to={"/customers/delete/" + row.id} size="sm">
                    Remove
                </Button>
            </>
        )
    }

    const nameFormatter = function (cell, row, rowIndex) {
        return (
            <Link to={"/customers/show/" + row.id} size="sm">
                {cell}
            </Link>
        )
    }

    const columns = [
        {
            dataField: 'name',
            text: "",
            formatter: nameFormatter,
            filter: textFilter({ placeholder: "Customer...", delay: 300, caseSensitive: false }),
            headerStyle: () => {
                return { width: "20%" };
            }
        }, {
            dataField: 'notificationLevel',
            text: "Notification Level",
            formatter: (cell, row) => { return LogLevels.StringFromInt(cell) },
            headerStyle: () => {
                return { width: "15%" };
            }
        }, {
            dataField: 'comment',
            text: "Comment"
        }, {
            dataField: 'id',
            isDummyField: true,
            text: "Actions",
            formatter: editButton,
            align: 'right',
            headerStyle: () => {
                return { width: "145px" };
            }
        }
    ];

    return (
        <Container>
            <h2 className="my-3 text-center">Customers</h2>
            <Helmet>
                <title>Customers - Anubis</title>
            </Helmet>
            {!!toast && (
                <Row>
                    <Col>{toast}</Col>
                </Row>
            )}
            <Row>
                <Col>
                    <Button className="float-end mb-2" as={Link} to={"/customers/create"} size="sm" >
                        Create Customer
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    {(data === null) ? "loading...." : (
                        <BootstrapTable
                            keyField="id"
                            data={data}
                            columns={columns}
                            filter={filterFactory()}
                            classes="log-table"
                            pagination={paginationFactory({ hideSizePerPage: true, sizePerPage: 100 })}
                            condensed
                            bordered={false}
                        />
                    )}
                </Col>
            </Row>
        </Container>
    )
}

export default CustomerList;
