import React from "react";
import * as Constants from '../../utils/constants';

import {
  BarChart, Bar, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip, Text
} from 'recharts';

const LocationUsageChart = function (props) {

  let plotData = props.data;
  const xticks = []

  if (plotData !== undefined && plotData !== null) {
    // Select top N items
    if (props.top !== -1) {
      plotData = []
      const end = Math.min(props.top, props.data.length);
      for (let i = 0; i < end; i++) plotData.push(props.data[i])
    }

    if (props.flop !== -1) {
      plotData = []
      const start = Math.max(0, props.data.length-props.flop);
      for (let i = props.data.length - 1; i >= start ; i--) plotData.push(props.data[i])
    }

    // adjust xticks
    for (let i = 0; i < plotData.length; i++) {
      xticks.push({ tick: 2 })
    }
  }
  
  return (
    <ResponsiveContainer width="100%" height={props.height}>
      <BarChart data={plotData} margin={{ top: 20, right: 30, left: 20, bottom: 50 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis textAnchor="start" interval={0} dataKey={props.xkey} angle={45} dx={0} dy={0} />
        <YAxis label={<Text x={0} y={0} dx={props.ylabeloffsetx} dy={props.height / 2 + 50 + props.ylabeloffset} offset={0} angle={-90}>{props.ylabel}</Text>} />
        <Tooltip />

        <Bar isAnimationActive={false} dataKey={props.ykey} fill={Constants.PLOT_COLORS[0]} />

        <Bar isAnimationActive={false} dataKey="sys0" fill={Constants.PLOT_COLORS[1]} stackId="s" />
        <Bar isAnimationActive={false} dataKey="sys1" fill={Constants.PLOT_COLORS[2]} stackId="s" />
        <Bar isAnimationActive={false} dataKey="sys2" fill={Constants.PLOT_COLORS[3]} stackId="s" />
        <Bar isAnimationActive={false} dataKey="sys3" fill={Constants.PLOT_COLORS[4]} stackId="s" />

      </BarChart>
    </ResponsiveContainer>
  );
};

LocationUsageChart.defaultProps = {
  height: 250,
  xlabel: "X-Label",
  xkey: "gateIdString",
  ylabel: "Y-Label",
  ylabeloffset: 0,
  ylabeloffsetx: 40,
  ykey: "cnt",
  fill: Constants.PLOT_COLORS[0],
  top: -1,
  flop: -1
}

export default LocationUsageChart;
