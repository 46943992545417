import React from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import { Col, Row, Button, Container, Alert } from "react-bootstrap";
import Moment from 'react-moment'
import BootstrapTable from 'react-bootstrap-table-next';
import * as Constants from '../../utils/constants';

const XpuList = (props) => {


    const [data, setData] = React.useState(null)
    const [toastDismissed, setToastDismissed] = React.useState(false)

    let toast = "";
    if (!!props.location.state?.toast && toastDismissed === false) {
        toast = (<Alert variant="success" onClose={() => setToastDismissed(true)}>{props.location.state.toast}</Alert>)
    }

    React.useEffect(() => {
        axios.get(Constants.API_BASE + 'api/xpu')
            .then(res => {
                setData(res.data);
            })
    }, [])

    const columns = [
        {
            dataField: 'name',
            text: "XPU Id",
            formatter: (cell, row) => { 
                if(row.decommissioned === true) {
                    return <Link to={"/xpus/edit/" + row.id} className="text-danger"><s>{row.name}</s></Link>
                }
                return <Link to={"/xpus/edit/" + row.id}>{row.name}</Link>
            },
            headerStyle: () => {
                return { width: "100px" };
            }
        },
        {
            dataField: 'systemType',
            text: "Type",
            headerStyle: () => {
                return { width: "60px" };
            }
        }, {
            dataField: 'Gates',
            text: "System Id",
            formatter: (cell, row) => {
                return row.Gates.map((v, i) => { return <span key={"system"+row.id+"-"+v.id+"-"+i}><Link to={"/systems/show/" + v.id}>{v.gateId}</Link><br /></span>})
            },
            headerStyle: () => {
                return { width: "80px" };
            }
        }, {
            dataField: 'Customers',
            isDummyField: true,
            text: "Customer",
            formatter: (cell, row) => {
                return row.Gates.map((v, i) => { return (<span key={"customer"+row.id+"-"+v.id+"-"+i}>{v.Customer.name}<br /></span>)})
            },
            headerStyle: () => {
                return { width: "180px" };
            }
        }, {
            dataField: 'Location',
            isDummyField: true,
            text: "Location",
            formatter: (cell, row) => {
                return row.Gates.map((v, i) => {
                    const locName = v.Location === null ? "?" : v.Location.name
                    return <span key={"loc"+row.id+"-"+v.id+"-"+i}>{locName}<br /></span>
                })
            },
            headerStyle: () => {
                return { width: "180px" };
            }
        }, {
            dataField: 'comment',
            isDummyField: true,
            text: "Comment",
            formatter: (cell, row) => {
                if(row.comment.length > 60)
                    return row.comment.substring(0,57)+"..."
                return row.comment
            }
        }, {
            dataField: 'FirstSeen',
            isDummyField: true,
            text: "First Seen",
            formatter: (cell, row) => {
                return row.Gates.map((v, i) => {return <span key={"first"+row.id+"-"+v.id+"-"+i}><Moment local format="DD.MM.YYYY HH:mm:ss">{v.createdAt}</Moment><br /></span>})
            },
            headerStyle: () => {
                return { width: "140px" };
            }
        }
    ];

    if(data === null)
        return <div>Loading..</div>

    return (
        <Container>
            <h2 className="my-3 text-center">XPU Management</h2>
            {!!toast && (
                <Row>
                    <Col>{toast}</Col>
                </Row>
            )}
            <Row>
                <Col>
                    <Button as={Link} to="/xpus/create/" size="sm" className="me-1 float-end">
                        Add new XPU manually
                    </Button>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col>
                    <BootstrapTable
                        keyField="id"
                        data={data}
                        columns={columns}
                        classes="log-table"
                        condensed
                        bordered={false}
                    />
                </Col>
            </Row>

        </Container>
    )
}

export default XpuList;
