import React from "react";
import { Col, Spinner, } from "react-bootstrap"
import { Chart } from "react-google-charts";
import axios from 'axios';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons'

import * as Constants from '../../utils/constants';

const options = {
    title: null,
    chartArea: { left: 50, top: 30, right: 50, bottom: 50 },
    legend: "top",
    backgroundColor: "transparent",
    colors: Constants.PLOT_COLORS,
    series: [
        {
            targetAxisIndex: 0,
            pointsVisible: true
        },
        {
            targetAxisIndex: 1,
            pointsVisible: true
        }
    ],
    hAxis: { minValue: 0, maxValue: 23 },
    vAxes: [
        {
            title: '# Messages',
            minValue: 0,
            titlePosition: 'in'
        },
        {
            title: '# Events',
            minValue: 0
        }
    ]
};

const MaintenanceTimeCol = (props) => {

    const [data, setData] = React.useState(null);

    const gId = props.systemId;

    React.useEffect(() => {
        if (gId === null) return;

        axios.get(Constants.API_BASE + 'api/diag/maintenanceTime?systemId=' + gId)
            .then(res => {
                setData(res.data);
            })
            .catch(err => {
                console.error(err);
            });
    }, [gId])

    if (gId === null) {
        console.warn("no system id set.")
        return <></>
    }

    const thisData = [['Hour', 'Messages', 'Measurements']]



    if (data !== undefined && data !== null) {
        for (let i = 0; i < data.length; i++) {
            thisData.push([
                data[i]['hourGrp'], //String(data[i]['hourGrp']).padStart(2, "0")+":00",
                data[i]['nMessagesAvg'],
                data[i]['nMeasurementsAvg']
            ])
        }
    }
    
    if (thisData.length === 1) {
        return <Col xs={12} sm={3} className="panel text-center">
            <span className="mt-5 d-block">No data for the last 14 days.</span>
        </Col>
    }

    return (
        <Col xs={12} sm={3} className="panel">
            <h3 align="center">Usage Profile<OverlayTrigger overlay={<Tooltip>Average number of messages and measurements in the last 14 days.</Tooltip>} placement="bottom">
                <span><FontAwesomeIcon icon={faCircleQuestion} size="sm" color="#00000088" style={{ marginBottom: "0px", marginLeft: "8px" }} /></span>
            </OverlayTrigger></h3>

            {(data === null) ? <Spinner animation="border" style={{ position: "relative", top: "15%", left: "50%" }} /> : (

                <Chart
                    chartType="AreaChart"
                    height={350}
                    data={thisData}
                    options={options}
                />

            )}
        </Col>
    );
};

MaintenanceTimeCol.defaultProps = {
    systemId: null
}

export default React.memo(MaintenanceTimeCol);
