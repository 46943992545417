import React from "react";
import axios from 'axios';
import { Row, Col, Form, Button, Stack } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as Constants from '../utils/constants';
import { useKeycloak } from '../utils/keycloak-hook'
import LoadingSpinner from "./LoadingSpinner";

const TimeTrackForm = function (props) {

    const { profile } = useKeycloak();
    const [customerData, setCustomerData] = React.useState(null);
    const [projects, setProjects] = React.useState([]);
    const [logType, setLogType] = React.useState(props.default.type);
    const customerId = props.customerId;

    React.useEffect(() => {
        axios.get(Constants.API_BASE + 'api/customer/' + customerId).then(res => setCustomerData(res.data)).catch(err => console.error(err))
        axios.get(Constants.API_BASE + 'api/timetrack/projects?customerId=' + customerId).then(res => setProjects(res.data)).catch(err => console.error(err))
    }, [customerId])

    const { register, handleSubmit, errors, setValue } = useForm({
        defaultValues: {
            CustomerId: customerId,
            email: props.default.email === undefined ? profile.email : props.default.email,
            uid: props.default.uid === undefined ? profile.sub : props.default.uid,
            project: props.default.project,
            effortMinutes: props.default.effortMinutes,
            description: props.default.description,
            meetingTime: props.default.meetingTime?.slice(0, 10)
        }
    })

    const onSubmit = data => {

        if (logType === undefined) {
            alert("Meeting Type must be defined!")
            return
        }

        data.type = logType;
        props.saveHandler(data)
    }

    const onSelectExistingProject = (e) => {
        setValue("project", e.target.value)
    }

    if (customerData === null) 
        return <LoadingSpinner text="Loading customer data" />

    return (

        <Row>
            <Col>
                <h2 className="text-center">{props.header}</h2>

                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-2">
                        <Form.Label>Customer</Form.Label>
                        <Form.Control type="text" name="name" defaultValue={customerData.name} disabled={true} />
                        <Form.Control type="hidden" name="CustomerId" ref={register({ required: true })} />
                        <Form.Control type="hidden" name="email" ref={register({ required: true })} />
                        <Form.Control type="hidden" name="uid" ref={register({ required: true })} />
                        <Form.Control.Feedback type="invalid">Please enter a name</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-2 text-center">
                        <Form.Label>Meeting Type:</Form.Label>
                        <Button variant={parseInt(logType) === 1 ? "primary" : "secondary"} size="sm" className="me-2 ms-2" onClick={() => setLogType(1)}>{Constants.Map_TimetrackTypeNumeric_TimetrackTypeString[1]}</Button>
                        <Button variant={parseInt(logType) === 2 ? "primary" : "secondary"} size="sm" className="me-2" onClick={() => setLogType(2)}>{Constants.Map_TimetrackTypeNumeric_TimetrackTypeString[2]}</Button>
                        <Button variant={parseInt(logType) === 3 ? "primary" : "secondary"} size="sm" className="me-2" onClick={() => setLogType(3)}>{Constants.Map_TimetrackTypeNumeric_TimetrackTypeString[3]}</Button>
                        <Button variant={parseInt(logType) === 4 ? "primary" : "secondary"} size="sm" className="me-2" onClick={() => setLogType(4)}>{Constants.Map_TimetrackTypeNumeric_TimetrackTypeString[4]}</Button>
                        <Button variant={parseInt(logType) === 9 ? "primary" : "secondary"} size="sm" className="me-2" onClick={() => setLogType(9)}>{Constants.Map_TimetrackTypeNumeric_TimetrackTypeString[9]}</Button>
                    </Form.Group>

                    <Form.Group className="mb-2">
                        <Form.Label>Project</Form.Label>
                        <Stack direction="horizontal" gap={3}>
                            <Form.Control type="text" name="project" placeholder="Enter project name..." ref={register({ required: true })} isInvalid={errors?.project} />
                            <Form.Select aria-label="Default select example" size="sm" onChange={(e) => onSelectExistingProject(e)}>
                                <option value="" disabled selected>Select existing project...</option>
                                {projects.map((v,i) => <option key={'p'+i} value={v.project}>{v.project}</option>)}
                            </Form.Select>
                        </Stack>
                        <Form.Control.Feedback type="invalid">Please enter a project</Form.Control.Feedback>
                    </Form.Group>


                    <Form.Group className="mb-2">
                        <Form.Label>Meeting Date</Form.Label>
                        <Form.Control type="date" name="meetingTime" ref={register({ required: true })} isInvalid={errors?.meetingTime} />
                        <Form.Control.Feedback type="invalid">Please provide a date.</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-2">
                        <Form.Label>Duration in Minutes</Form.Label>
                        <Form.Control as="input" rows="3" name="effortMinutes" placeholder="Effort in minutes" ref={register({ required: true })} isInvalid={errors?.effortMinutes} />
                        <Form.Control.Feedback type="invalid">Please estimate the effort in hours.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-2 text-center">
                        <Button variant="secondary" className="me-2" size="sm" onClick={() => setValue("effortMinutes", 5)}>5&thinsp;min</Button>
                        <Button variant="secondary" className="me-2" size="sm" onClick={() => setValue("effortMinutes", 10)}>10&thinsp;min</Button>
                        <Button variant="secondary" className="me-2" size="sm" onClick={() => setValue("effortMinutes", 15)}>15&thinsp;min</Button>
                        <Button variant="secondary" className="me-2" size="sm" onClick={() => setValue("effortMinutes", 20)}>20&thinsp;min</Button>
                        <Button variant="secondary" className="me-2" size="sm" onClick={() => setValue("effortMinutes", 30)}>30&thinsp;min</Button>
                        <Button variant="secondary" className="me-2" size="sm" onClick={() => setValue("effortMinutes", 45)}>45&thinsp;min</Button>
                        <Button variant="secondary" className="me-2" size="sm" onClick={() => setValue("effortMinutes", 60)}>60&thinsp;min</Button>
                        <Button variant="secondary" size="sm" onClick={() => setValue("effortMinutes", 90)}>90&thinsp;min</Button>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Description (optional)</Form.Label>
                        <Form.Control as="textarea" name="description" rows="3" ref={register()} placeholder="Enter an optional description or note..." />
                    </Form.Group>

                    <Button variant="primary" className="float-end mt-1" type="submit">Save</Button>
                </Form>
            </Col>
        </Row>
    );
};

TimeTrackForm.defaultProps = {
    customerId: 1,
    default: {
        email: undefined,
        uid: undefined,
        type: undefined
    },
    header: "Add Time Tracking Record"
}

export default TimeTrackForm;
