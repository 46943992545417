import React from "react";
import Moment from 'react-moment';
import { ListGroup } from "react-bootstrap"

import * as Constants from '../utils/constants';
import 'moment-timezone';
import EditMaintenanceLogModal from "./EditMaintenanceLogModal";

const MaintenanceLogList = (props) => {
    const [editData, setEditData] = React.useState(null);

    return (
        <>
            <EditMaintenanceLogModal show={editData !== null} data={editData} onHide={() => { props.refreshDataFunc(); setEditData(null) }} />

            <ListGroup variant="flush" className="panel-list">
                {props.data != null && props.data.map((v) => {

                    let editButton = "";
                    if(props.hideEdit === false) {
                        editButton = <span onClick={() => setEditData(v)}>🖊️</span>
                    }
                    const version = !!v.versionMetrix ? v.versionMetrix : "unknown";
                    return (
                        <ListGroup.Item key={"mlog-" + v.id} onClick={() => props.onClick(v)}>
                            <div><strong style={{ color: Constants.Map_LogTypeNumeric_Color[v.type] }}>{Constants.Map_LogTypeNumeric_LogTypeString[v.type]}</strong> by <strong>{v.email}</strong> &#8211; <span className="text-muted">MetriX Version: {version} &#8211; {<Moment local format="DD.MM.YYYY HH:mm:ss">{v.createdAt}</Moment>}</span> {editButton}</div>
                            <div>{v.description}</div>
                            {v.resolution !== "" && (
                                <div style={{ display: 'flex' }}>
                                    <span>✔️</span>
                                    <span>{v.resolution}</span>
                                </div>
                            )}
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
        </>
    )
}

MaintenanceLogList.defaultProps = {
    data: [],
    onClick: () => { },
    refreshDataFunc: () => {console.warn("no refresh data func set")},
    hideEdit: false
}

export default MaintenanceLogList;