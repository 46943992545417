import React from "react";
import { Container, Row, Col } from "react-bootstrap"

const ErrorPage = (props) => {
  
  return (
    <Container className="container-sm mt-3" size="sm" >
      <Row>
        <Col>
          <h2 className="text-center mt-3">Error</h2>
          <p className="text-center mt-2">The page you requested cannot be opened. It does not exist or you do not own the required privileges.</p>

          {props.requiredRole !== null && (
            <p className="text-center mt-2">Additional information: you might be missing the following role: <code>{props.requiredRole}</code>.</p>
          )}

        </Col>
      </Row>
    </Container>
  )
};

ErrorPage.defaultProps = {
  requiredRole: null
}

export default ErrorPage;
