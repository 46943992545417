import React from "react";
import GoogleChart from "./googleChart";

const ToGoogleChartFormat = (g) => {
  const res = [];
  res.push(["ID", "Time", "YKey", "Type", "Count"])
  for(let i=0; i<g.length; i++) {

    let t = "Measurement"
    if(g[i].eventType === 2) t = "Startup"
    else if(g[i].eventType === 3) t = "Init"
    else if(g[i].eventType === 4) t = "Calib Success"
    else if(g[i].eventType === 5) t = "Calib Failed"
    else if(g[i].eventType === 6) t = "Lost Device"
    else if(g[i].eventType === 7) t = "Lost Local"
    else if(g[i].eventType === 8) t = "Lost Remote"

    res.push([
      "",
      new Date(g[i].block),
      g[i].eventType,
      t,
      g[i].cnt
    ])
  }
  return res;
}

function subtractHours(numOfHours, date = new Date()) {
  const d = new Date(date)
  d.setHours(date.getHours() - numOfHours);
  return d;
}

function addHours(numOfHours, date = new Date()) {
  const d = new Date(date)
  d.setHours(date.getHours() + numOfHours);
  return d;
}

const EventsPerTimespan = (props) => {
  
  const data = ToGoogleChartFormat(props.data);

  let minDate = subtractHours(24, new Date());
  let maxDate = new Date();

  if(data.length > 1) {
    if(data.length === 2) {
      minDate = subtractHours(6, data[1][1])
      maxDate = addHours(6, data[1][1])
    } else {
      minDate = subtractHours(6, data[1][1])
      maxDate = addHours(6, data[data.length-2][1])
    }
  } else {
    return (<p style={{textAlign: "center", marginTop: 20}}>No non-measurement events occured in the last 7 days</p>)
  }

  const gchartOptions = {
      backgroundColor: "transparent",
      legend: {
          position: "bottom"
      },
      vAxis: {
        ticks: [],
        minValue: 0.5,
        maxValue: 8.5
      },
      hAxis: {
        format: 'd.M HH:mm',
        minValue: minDate,
        maxValue: maxDate,
      },
      chartArea:{width:'95%',height:'75%', top: 10, bottom: 42, backgroundColor: "transparent"},
      fontSize: 12,
      fontName: "'Segoe UI', Roboto"
    };
  return (
      <GoogleChart
          chartType="BubbleChart"
          width={props.width}
          height={props.height}
          data={data}
          options={gchartOptions}
        />
  )
}

EventsPerTimespan.defaultProps = {
    height: "380px",
    width: "100%",
}

export default EventsPerTimespan;