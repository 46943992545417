import React, { Component } from "react";
import PropTypes from 'prop-types';

import DateRangePicker from 'react-bootstrap-daterangepicker';
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
//import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';

export default class TimespanFilter extends Component {
    static propTypes = {
        column: PropTypes.object.isRequired,
        onFilter: PropTypes.func.isRequired
    }
    constructor(props) {
        super(props);


        this.refFromDate = React.createRef();
        this.refToDate = React.createRef();

        this.filter = this.filter.bind(this);
        this.getValue = this.getValue.bind(this);
    }

    getValue() {
        return { from: this.refFromDate.current.value, to: this.refToDate.current.value };
    }

    filter() {
        this.props.onFilter(this.getValue());
    }

    handleEvent(event, picker) {
        console.log(picker.startDate);
    }
    handleCallback(start, end, label) {
        console.log(start, end, label);
    }


    handleApply = (event, picker) => {
        picker.element.val(
            picker.startDate.format('MM/DD/YYYY') +
            ' - ' +
            picker.endDate.format('MM/DD/YYYY')
        );
    };
    handleCancel = (event, picker) => {
        picker.element.val('');
    };    

    render() {

        

        return (
            
            <label className="filter-label">
            <span className="sr-only">Filter by</span>
            <DateRangePicker
            onApply={this.handleApply}
            onCancel={this.handleCancel}    
            //onEvent={this.handleEvent}
            //onCallback={this.handleCallback}
                initialSettings={{
                    timePicker: true,
                    timePicker24Hour: true,
                    autoUpdateInput: false,
                    locale: {
                      cancelLabel: 'Clear',
                    },
                }}
            >
                <input placeholder="Filter..." className="filter text-filter form-control"/>
            </DateRangePicker>
            </label>
        );
    }
};