import React from "react";
import { Spinner } from "react-bootstrap";

const LoadingSpinner = (props) => (
  <div className='text-center mt-5'>
    <Spinner animation="border" role="status"></Spinner>
    <p className='text-center mt-2'>{props.text}</p>
  </div>
);

LoadingSpinner.defaultProps = {
  text: "Loading..."
}

export default LoadingSpinner;
