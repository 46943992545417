import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Moment from 'react-moment';

const TemporalDeltaTable = function(props) {
    let tableJsx = "";
    if (props.data === null || props.data.length === 0) {
        tableJsx = (
            <tr>
                <td colSpan="2" className="text-center">
                    <br />no data<br />
                    <span style={{fontSize: "300%"}}>🎉</span>
                </td>
            </tr>
        )
    } else {
        tableJsx = props.data.map((v, index) => {
            //console.log("map", index, v)

            return (<tr key={"temporalDelta" + index}>
                <td><Link to={"/systems/show/" + v.id}>{v.systemId}</Link></td>
                <td><Moment local format="DD.MM.YYYY HH:mm:ss">{v.lastMessage}</Moment></td>
                <td>{v.deltaMinutes}</td>
            </tr>)
        })
    }
    

    return (
        <Table size="sm" responsive>
            <thead>
              <tr>
                <th style={{width: '20%'}}>System</th>
                <th >Last Message</th>
                <th style={{width: '30%'}}>Δ Minutes</th>
              </tr>
            </thead>
            <tbody>
              {tableJsx}
            </tbody>
          </Table>        
    )
};

TemporalDeltaTable.defaultProps = {
    data: null,
    title: "undefined title",
    rowKey: "some-key",
}


export default TemporalDeltaTable;
