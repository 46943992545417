import React from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import { Col, Row, Button, Container, Alert } from "react-bootstrap";
import Moment from 'react-moment'

import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';

import moment from 'moment';

import * as LogLevels from '../../utils/logLevels';
import * as Constants from '../../utils/constants';
import { Helmet } from "react-helmet";

const editButton = function (cell, row, rowIndex) {
    return (
        <>
            <Button as={Link} to={"/systems/edit/" + row.id} size="sm" className="me-1">
                Edit
            </Button>
            <Button as={Link} to={"/systems/delete/" + row.id} size="sm">
                Remove
            </Button>
        </>
    )
}

const status = function (cell, row) {

    if (!!(row.lastMessage === 0))
        return "⬛";

    let t = moment().diff(row.lastMessage, 'hours')

    if (t < 24) return "🟩"
    else if (t < 48) return "🟨"
    else return "🟥"
}

const GetEffectiveLocation = (cell, row) => {
    if (cell == null) return <span style={{ opacity: 0.5 }}>not defined</span>
    return cell
}

const FormatSystemId = (cell, row) => {
    let mute = ''
    if (row.mutedUntil !== null) {
        const refDate = new Date()
        refDate.setHours(0, 0, 0, 0);
        const dbDate = new Date(row.mutedUntil)
        dbDate.setHours(0, 0, 0, 0);
        const mutedPassed = (dbDate < refDate)
        if (!mutedPassed) mute = '🔇'
    }
    return <Link to={"/systems/show/" + row.id}>{status(cell, row)} {cell}{mute}</Link>
}

const GateList = (props) => {

    const [systemData, setSystemData] = React.useState(null)
    const [toastDismissed, setToastDismissed] = React.useState(false)

    let toast = "";
    if (!!props.location.state?.toast && toastDismissed === false) {
        toast = (<Alert variant="success" onClose={() => setToastDismissed(true)} dismissible>{props.location.state.toast}</Alert>)
    }

    const fetchDataCallback = React.useCallback((newState = null) => {
        let params = {};

        if (newState !== null) {
            if (!!newState?.filters?.gateId) {
                params.gateId = newState.filters.gateId.filterVal;
            }
        }

        Promise.allSettled([
            axios.get(Constants.API_BASE + 'api/gate', { params: params }),
            axios.get(Constants.API_BASE + 'api/camera'),
            axios.get(Constants.API_BASE + 'api/customer'),
            axios.get(Constants.API_BASE + 'api/location')
        ])
            .then((results) => {
                const camDict = {}
                
                results[1].value.data.forEach(d => {
                    if (Object.hasOwn(camDict, d.GateId)) {
                        camDict[d.GateId].push(d)
                    } else {
                        camDict[d.GateId] = [d]
                    }
                    
                })

                const customers = results[2].value.data.map(i => { return {label: i.name, value: i.name}})
                const locations = results[3].value.data.map(i => { return {label: i.name, value: i.name}})

                setSystemData({ list: results[0].value.data, cameras: camDict, customers: customers, locations: locations })
            })

    }, []);

    React.useEffect(() => {
        fetchDataCallback();
    }, [fetchDataCallback])

    const GetCameraTypes = (cell, row) => {
        const knownCams = systemData.cameras[row.id];
        const joinChars = ", ";
    
        if (!!knownCams) {
            let types = knownCams.map(k => { return k.cameraType === null ? "❔" : k.cameraType }).join(joinChars)
            return <span>{types}</span>
        } else {
            return <span style={{ opacity: 0.5 }}>No calibration data</span>
        }
    }

    const columns = [
        {
            dataField: 'gateId',
            text: '',
            filter: textFilter({ placeholder: "System ID...", delay: 300, caseSensitive: false }),
            formatter: (cell, row) => FormatSystemId(cell, row),
            headerStyle: () => {
                return { width: "12%" };
            }
        }, {
            dataField: 'notificationLevel',
            text: "Level",
            formatter: (cell, row) => { return LogLevels.GetEffectiveLogLevelString(cell, row) },
            headerStyle: () => {
                return { width: "75px" };
            },
        }, {
            dataField: 'Cameras',
            text: "",
            formatter: (cell, row) => { return GetCameraTypes(cell, row) },
        }, {
            dataField: 'Customer.name',
            text: "",
            filter: selectFilter({
                placeholder: "Customer...",
                options: systemData == null ? [] : systemData.customers
            })
        }, {
            dataField: 'Location.name',
            //text: "Location",
            filter: selectFilter({
                placeholder: "Location...",
                options: systemData == null ? [] : systemData.locations
            }),
            formatter: (cell, row) => { return GetEffectiveLocation(cell, row) },
        }, {
            dataField: 'Version',
            text: "MetriX",
            headerStyle: () => {
                return { width: "80px" };
            }
        }, {
            dataField: 'lastMessage',
            formatter: (cell) => { return <Moment local format="DD.MM.YYYY HH:mm:ss">{cell}</Moment> },
            text: "Last Message",
            headerStyle: () => {
                return { width: "130px" };
            }
        }, {
            dataField: 'id',
            isDummyField: true,
            text: "Actions",
            formatter: editButton,
            align: 'right',
            headerStyle: () => {
                return { width: "135px" };
            }
        }
    ];

    return (
        <Container fluid>
            <h2 className="my-3 text-center">All Systems</h2>
            <Helmet>
                <title>Systems - Anubis</title>
            </Helmet>
            {!!toast && (
                <Row>
                    <Col>{toast}</Col>
                </Row>
            )}
            <Row>
                <Col>
                    <Button className="float-end mb-2" as={Link} to={"/systems/create"} size="sm" >
                        Create new System manually
                    </Button>
                </Col>
            </Row>
            <Row style={{ justifyContent: "center" }}>
                <p>Status: last log message received within the last 24 hours <span style={{ marginRight: "0.25em", fontSize: "80%" }}>🟩</span>, 48 hours <span style={{ marginRight: "0.25em", fontSize: "80%" }}>🟨</span> or more <span style={{ marginRight: "0.25em", fontSize: "80%" }}>🟥</span></p>
            </Row>
            <Row>
                <Col>
                    {(systemData === null) ? "loading...." : (
                        <BootstrapTable
                            keyField="id"
                            data={systemData.list}
                            columns={columns}
                            filter={filterFactory()}
                            classes="log-table"
                            pagination={paginationFactory({ hideSizePerPage: true, sizePerPage: 25 })}
                            condensed
                            bordered={false}
                        />
                    )}
                </Col>
            </Row>
        </Container>
    )
}

export default GateList;
