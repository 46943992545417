import React from "react";
import axios from 'axios';
import Moment from 'react-moment';
import { Col, Spinner } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter, customFilter } from 'react-bootstrap-table2-filter';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import TimespanFilter from "./timespanFilter";

import * as LogLevels from '../utils/logLevels';
import * as Constants from '../utils/constants';

import 'moment-timezone';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const defaultShownPerPage = 50;

const getFetchParams = (fixedSystemId, newState) => {
    let params = {
        offset: 0,
        limit: defaultShownPerPage,
        order: "timestamp",
        orderDir: "DESC"
    }

    if (fixedSystemId !== null) {
        params.gateId = fixedSystemId;
    }

    if (newState !== null) {
        params.offset = (newState.page - 1) * newState.sizePerPage;
        params.limit = newState.sizePerPage;

        if (!!newState?.filters?.timestamp) {

            if (newState.filters.timestamp.filterVal.from !== "")
                params.timestampFrom = newState.filters.timestamp.filterVal.from;

            if (newState.filters.timestamp.filterVal.to !== "")
                params.timestampTo = newState.filters.timestamp.filterVal.to;
        }

        if (!!newState?.filters?.level) {
            params.minLevel = newState.filters.level.filterVal;
        }

        if (!!newState?.filters?.gateIdString) {
            params.gateIdString = newState.filters.gateIdString.filterVal;
        }

        if (!!newState?.filters?.logger) {
            params.logger = newState.filters.logger.filterVal;
        }
    }

    return params;
}

const fetchData = (fixedSystemId, setTableProps, newState, refreshFunc=null) => {

    const params = getFetchParams(fixedSystemId, newState);
    axios.get(Constants.API_BASE + 'api/message', { params: params,})
        .then(res => {
            const newState = {
                page: (params.offset / params.limit) + 1,
                data: res.data.rows,
                dataCount: res.data.count
            }

            setTableProps(newState)
            
            if(refreshFunc != null) {
                refreshFunc()
            }
        })
        .catch(err => {
            console.log(err);
        })
}

const LogMessageList = (props) => {

    const [tableProps, setTableProps] = React.useState({
        page: 1,
        data: null,
        dataCount: 0
    });

    const [refreshIcon, setRefreshIcon] = React.useState(false)

    const fixedSystemId = (!!props.fixedSystemId) ? props.fixedSystemId : null;

    const fetchCallback = React.useCallback(() => {
        fetchData(fixedSystemId, setTableProps, null);
    }, [fixedSystemId]);

    React.useEffect(() => {
        fetchCallback()
    }, [fetchCallback])

    const handlePageChange = function (page, sizePerPage) { }

    const onTableChange = (type, newState) => {
        //fetchData(newState);
        fetchData(fixedSystemId, setTableProps, newState)
    }

    const selectLevel = {
        10: 'DEBUG',
        20: 'INFO',
        30: 'WARN',
        40: 'ERROR',
        50: 'FATAL'
    };

    const messageFormatter = (row) => {
        if (row.exception === "" || row.exception === null) {
            return row.message
        } else {
            return (
                <>
                    {row.message} <br /><strong>Exception:</strong><br /><pre>{row.exception}</pre>
                </>
            );
        }
    }

    let percent = 100;
    const columns = []

    let colW = 8;
    columns.push({
        dataField: 'createdAt', text: '',
        formatter: (cell, row) => {
            return (<Moment local format="DD.MM.YY HH:mm:ss">{cell}</Moment>)
        },
        filter: customFilter({}),
        filterRenderer: (onFilter, column) => (<TimespanFilter onFilter={onFilter} column={column} />),
        headerStyle: () => { return { width: "120px" }; }
    });
    percent -= colW;

    columns.push({
        dataField: 'level', text: '',
        filter: selectFilter({ options: selectLevel, placeholder: "Level...", delay: 1000, caseSensitive: false }),
        formatter: (cell, row) => { return LogLevels.IconFromInt(cell) },
        align: "center",
        headerStyle: () => {
            return { width: "80px" };
        }
    });
    percent -= colW;

    if (props.hideSystemId !== true) {
        columns.push({
            dataField: 'gateIdString', text: '',

            filter: textFilter({ placeholder: "System...", delay: 1000, caseSensitive: false }),
            formatter: (cell, row) => { return <Link to={"/systems/show/" + row.GateId}>{row.Gate.gateId}</Link> },
            headerStyle: () => {
                return { width: "130px" };
            }
        });
        percent -= colW;
    }

    if (props.hideCustomer !== true) {
        columns.push({
            dataField: 'Gate.Customer.name',
            text: "Customer",
            headerStyle: () => {
                return { width: "200px" };
            }
        });
        percent -= colW;
    }

    columns.push({
        dataField: 'logger', text: '',
        filter: textFilter({ placeholder: "Logger...", delay: 1000, caseSensitive: false }),
        headerStyle: () => {
            return { width: "130px" };
        },
        formatter: (cell, row) => { return `${row['logger']} (${row['version']})` },
    });
    percent -= colW;

    columns.push({
        dataField: 'message',
        text: "Message",
        headerStyle: () => {
            return { width: percent + "%" };
        },
        formatter: (cell, row) => { return messageFormatter(row) },
    });

    const pageOptions = {
        onPageChange: handlePageChange,
        page: tableProps.page,
        sizePerPage: defaultShownPerPage,
        totalSize: tableProps.dataCount,
        hideSizePerPage: true,
    }

    const refresh = async () => {
        
        if(refreshIcon === false) {
            setRefreshIcon(true)
            fetchData(fixedSystemId, setTableProps, null, () => setRefreshIcon(false))
           
        }
    }

    return (
        <Col className={props.colClassName} xs={props.xs}>
            {(props.header !== null) && (
                <h3 style={{ float: "left" }}>
                    {props.header}
                </h3>
            )}

            {(tableProps.data === null) ? (<Spinner animation="border" style={{ position: "relative", top: "5%", left: "50%", marginTop: "100px" }} />) : (
                <PaginationProvider pagination={paginationFactory(pageOptions)}>
                    {
                        ({
                            paginationProps,
                            paginationTableProps
                        }) => (
                            <>
                                {props.inlineTitle != null && (
                                    <h2 style={{marginBottom:0, marginTop: 8, float:"left"}}>
                                        {props.inlineTitle}
                                        {refreshIcon === false ? (
                                            <FontAwesomeIcon onClick={() => refresh()} icon={faRefresh} size="sm"  color="#00000088" style={{ marginLeft: "8px", cursor: "hand" }} />
                                        ) : (
                                            <FontAwesomeIcon icon={faSpinner} size="sm"  color="#ff00000" style={{ marginLeft: "8px", cursor: "hand" }} />
                                        )}
                                    </h2>
                                )}
                                <PaginationListStandalone
                                    {...paginationProps}
                                />
                                <BootstrapTable
                                    keyField="id"
                                    data={tableProps.data}
                                    columns={columns}
                                    filter={filterFactory()}
                                    classes="log-table"
                                    bordered={false}
                                    condensed
                                    remote
                                    onTableChange={onTableChange}
                                    {...paginationTableProps}
                                />
                            </>
                        )
                    }
                </PaginationProvider>
            )}
        </Col>
    )

}

LogMessageList.defaultProps = {
    fixedSystemId: null,
    hideCustomer: false,
    hideSystemId: false,
    colClassName: "",
    header: null,
    xs: 12,
    inlineTitle: null
}


export default LogMessageList;
