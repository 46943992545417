import React from "react";
import * as Constants from '../../utils/constants';
import { Chart } from "react-google-charts";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfinity, faCalendar } from '@fortawesome/free-solid-svg-icons'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

const options = {
  title: null,
  pieHole: 0.4,
  is3D: false,
  backgroundColor: "transparent",
  colors: Constants.PLOT_COLORS
};

const VersionPieChart = function (props) {

  const [useAllData, setUseAllData] = React.useState(false);

  let plotData = (useAllData) ? props.dataAll : props.data30;
  const thisData = [['Version', 'Count']]

  if (plotData !== undefined && plotData !== null) {
    for (let i = 0; i < plotData.length; i++) {
      thisData.push([
        plotData[i][props.xkey] == null ? "unknown" : plotData[i][props.xkey],
        plotData[i][props.ykey]
      ])
    }
  }

  return (
    <div style={{ display: 'block', }}>
      <div style={{ display: 'block', float: 'left', padding: 5, borderRight: '2px solid #d1d4d5', zIndex: 999, position: 'relative', textAlign: 'center' }}>
        <OverlayTrigger overlay={<Tooltip>Analyze 30 days</Tooltip>} placement="right">
          <FontAwesomeIcon
            icon={faCalendar}
            className="nav-button"
            size="lg"
            style={{ marginBottom: 5, paddingRight: 2, color: (useAllData) ? Constants.COLORS.FONT : Constants.COLORS.FONT_HIGHLIGHT }}
            onClick={() => setUseAllData(false)}
          />
        </OverlayTrigger><br />
        <OverlayTrigger overlay={<Tooltip>Analyze all data</Tooltip>} placement="right">
          <FontAwesomeIcon
            icon={faInfinity}
            className="nav-button"
            size="lg"
            style={{ paddingRight: 2, color: (useAllData) ? Constants.COLORS.FONT_HIGHLIGHT : Constants.COLORS.FONT }}
            onClick={() => setUseAllData(true)}
          />
        </OverlayTrigger>
      </div>
      <div>
        <Chart
          chartType="PieChart"
          height={props.height}
          data={thisData}
          options={options}
        />
      </div>
    </div>
  );
};

VersionPieChart.defaultProps = {
  height: 350,
  xlabel: "X-Label",
  xkey: "versionMetrix",
  ylabel: "Y-Label",
  ylabeloffset: 0,
  ylabeloffsetx: 40,
  ykey: "c",
  fill: Constants.PLOT_COLORS[0],
  top: -1,
  flop: -1
}

export default VersionPieChart;
