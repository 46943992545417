import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons'

import SimpleCountTable from "../simpleCountTable";
import TemporalDeltaTable from "../TemporalDeltaTable";

const MaintenanceHealthMetrics = (props) => {

  const plotData = props.data


  const columns = [
    {
      dataField: 'serial',
      text: "Serial",
      sort: true,
      formatter: (cell, row) => { return <>{cell} ({row.cameraType})</> },
    }, {
      dataField: 'gateIdString',
      text: 'System ID',
      formatter: (cell, row) => { return <Link to={"/systems/show/" + row.GateId}>{cell}</Link> },
      sort: true,
    }, {
      dataField: 'roiStart',
      text: "Start ROI Miss. %",
      formatter: (cell, row) => { return (cell === null) ? 'null' : cell.toFixed(2) },
      sort: true,
      classes: 'numeric'
    }, {
      dataField: 'roiEnd',
      text: "End ROI Miss. %",
      formatter: (cell, row) => { return (cell === null) ? 'null' : cell.toFixed(2) },
      sort: true,
      classes: 'numeric'
    }, {
      dataField: 'roiDelta',
      text: "abs(Δ) ROI Start",
      formatter: (cell, row) => { return (cell === null) ? 'null' : cell.toFixed(2) },
      sort: true,
      classes: 'numeric-contrast'
    }, {
      dataField: 'angleStart',
      text: "Start °",
      formatter: (cell, row) => { return (cell === null) ? 'null' : cell.toFixed(2) },
      sort: true,
      classes: 'numeric'
    }, {
      dataField: 'angleEnd',
      text: "End °",
      formatter: (cell, row) => { return (cell === null) ? 'null' : cell.toFixed(2) },
      sort: true,
      classes: 'numeric'
    }, {
      dataField: 'angleDelta',
      text: "abs(Δ) ° Start",
      formatter: (cell, row) => { return (cell === null) ? 'null' : cell.toFixed(2) },
      sort: true,
      classes: 'numeric-contrast'
    },
  ];

  return (
      <Container fluid className="mb-5">
        <Row >
          <Col xs={12} sm={3} className="panel">
            <h3>Calibrations last 28 days<OverlayTrigger overlay={<Tooltip>Number of uploaded calibrations in the last 28 days</Tooltip>} placement="bottom">
              <span><FontAwesomeIcon icon={faCircleQuestion} size="sm" color="#00000088" style={{ marginBottom: "0px", marginLeft: "8px" }} /></span>
            </OverlayTrigger></h3>
            <div style={{ maxHeight: "300px", minHeight: "300px", overflowY: "scroll" }}>
              <SimpleCountTable title="# Calibrations" data={plotData.calibrations28Days} rowKey="failedCalibs" />
            </div>
          </Col>

          <Col xs={12} sm={3} className="panel">
            <h3>Weekly Failed Calibrations<OverlayTrigger overlay={<Tooltip>Number of failed calibrations in the last 7 days</Tooltip>} placement="bottom">
              <span><FontAwesomeIcon icon={faCircleQuestion} size="sm" color="#00000088" style={{ marginLeft: "8px" }} /></span>
            </OverlayTrigger></h3>
            <div style={{ maxHeight: "300px", minHeight: "300px", overflowY: "scroll" }}>
              <SimpleCountTable title="Failed Calibrations" data={plotData.failedCalibs7Days} rowKey="failedCalibs" />
            </div>
          </Col>

          <Col xs={12} sm={3} className="panel">
            <h3>
              Weekly Lost local Cameras
              <OverlayTrigger overlay={<Tooltip>Number of lost connections to the locel camera in the last 7 days</Tooltip>} placement="bottom">
                <span><FontAwesomeIcon icon={faCircleQuestion} size="sm" color="#00000088" style={{ marginLeft: "8px" }} /></span>
              </OverlayTrigger>
            </h3>
            <div style={{ maxHeight: "300px", minHeight: "300px", overflowY: "scroll" }}>
              <SimpleCountTable title="Lost Local" data={plotData.lostLocal7Days} rowKey="lostLocal" />
            </div>
          </Col>

          <Col xs={12} sm={3} className="panel">
            <h3>Weekly Lost remote Cameras
              <OverlayTrigger overlay={<Tooltip>Number of lost connections to the remote camera in the last 7 days</Tooltip>} placement="bottom">
                <span><FontAwesomeIcon icon={faCircleQuestion} size="sm" color="#00000088" style={{ marginLeft: "8px" }} /></span>
              </OverlayTrigger>
            </h3>
            <div style={{ maxHeight: "300px", minHeight: "300px", overflowY: "scroll" }}>
              <SimpleCountTable title="Lost Remote" data={plotData.lostRemote7Days} rowKey="lostRemote" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="panel">
            <h3>Calibration Health Metrics
              <OverlayTrigger overlay={<Tooltip>Δ's are computed by subtracting from the 'start' or 'interval' value from the 'end'. The interval value is calculated from the most recent values with a minimum age of 30 days. Values are averaged (5 samples).</Tooltip>} placement="bottom">
                <span><FontAwesomeIcon icon={faCircleQuestion} size="sm" color="#00000088" style={{ marginLeft: "8px" }} /></span>
              </OverlayTrigger>
            </h3>
            <BootstrapTable
              keyField="serial"
              data={plotData.calibrationsData}
              columns={columns}
              filter={filterFactory()}
              classes="log-table"
              pagination={paginationFactory({ hideSizePerPage: true, sizePerPage: 15 })}
              condensed
              bordered={false}
            />
          </Col>
        </Row>
        <Row >
          <Col xs={12} sm={4} className="panel">
            <h3>Temporal Drift between Logserver and System</h3>
            <div style={{ maxHeight: "300px", minHeight: "350px", overflowY: "scroll" }}>
              <TemporalDeltaTable data={plotData.temporalDeltas} rowKey="deltaMinutes" />
            </div>
          </Col>
          <Col xs={12} sm={8}>
          </Col>
        </Row>

        
      </Container>
  );
};

export default MaintenanceHealthMetrics;
