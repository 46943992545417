import React from "react";
import 'moment-timezone';
import moment from 'moment'


import {
  BarChart, Bar, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Text
} from 'recharts';

const MessagesPerTimespanChart = function (props) {

  const formatAsHourMinute = (tick) => {
    return moment(tick, 'X').format('HH:mm')
  };

  const getTicks = () => {
    // Date -> round down to last full hour -> subtract 24 hours
    const hour = 60 * 60;
    const dateSec = new Date() / 1000;
    const start = dateSec - (dateSec % (60 * 60)) - (24 * hour);

    let ticks = [];
    for (var i = 1; i <= 24; i++) ticks.push(start + i * hour);
    return ticks;
  }

  const xTicks = getTicks();
  const xEnd = moment().format('X');

  return (
    <ResponsiveContainer width="100%" height={props.height}>
      <BarChart data={props.data} margin={{ top: 10, right: 10, left: 10, bottom: 0 }} style={{marginLeft: -20}}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="block" tickFormatter={formatAsHourMinute} domain={[xEnd - (60 * 60 * 24), xEnd]} type="number" ticks={xTicks} />
        <YAxis label={<Text x={0} y={0} dx={50} dy={135} offset={0} angle={-90}>#Messages</Text>} />
        <Tooltip labelFormatter={formatAsHourMinute} />
        <Legend />
        <Bar isAnimationActive={false} dataKey="Info" stackId="a" fill="#3bba00" />
        <Bar isAnimationActive={false} dataKey="Warnings" stackId="a" fill="#e6c302" />
        <Bar isAnimationActive={false} dataKey="Errors" stackId="a" fill="#e66902" />
      </BarChart>
    </ResponsiveContainer>
  );
};

MessagesPerTimespanChart.defaultProps = {
  height: 250,

}

export default MessagesPerTimespanChart;