import axios from 'axios';
import React from "react";

import { Container } from 'react-bootstrap'

import * as Constants from '../../utils/constants';
import XpuFormComponent from '../../components/xpuFormComponent';
import XpuIdSuggestion from '../../components/XpuIdSuggestion';
import NotificationModal from "../../components/NotificationModal";
import useModal from "../../utils/useModal";

const XpuCreate = function (props) {

    const {data: notificationData, setData: setNotificationData} = useModal();

    const saveHandler = (formData) => {
        axios.post(Constants.API_BASE + 'api/xpu', formData,)
            .then(res => {
                console.log(res);
                props.history.push({
                    pathname: '/xpus',
                    state: { toast: `Created new xpu: ${formData.name}.` }
                });
            }).catch(err => setNotificationData({title: "Failed", text: err.message}))
    }

    return (
        <Container className="mt-3">
            <NotificationModal data={notificationData} />
            <h2 className="text-center">Create new XPU</h2>
            <XpuIdSuggestion />
            <XpuFormComponent saveHandler={saveHandler} />
        </Container>
    );
};

export default XpuCreate;
