import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from "react-router-dom";

const MaintenanceRemoteConfiguration = (props) => {

  const plotData = props.data

  const cuObj = {};
  plotData.remoteConfigs.forEach(x => {
    cuObj[x.customerName] = null
  });

  const customerNames = Object.keys(cuObj).sort().map(i => { return {label: i, value: i}})

  const columns = [
    {
      dataField: 'gateId',
      text: "System",
      sort: true,
      formatter: (cell, row) => { return <Link to={"/systems/show/" + row.id}>{cell}</Link> },
    }, {
      dataField: 'customerName',
      text: '',
      formatter: (cell, row) => { return <Link to={"/customers/edit/" + row.customerId}>{cell}</Link> },
      filter: selectFilter({options: plotData == null ? [] : customerNames})
    }, {
      dataField: 'systemUpdatetask',
      text: "UpdateTask",
      formatter: (cell, row) => { return (row.systemUpdatetask === 1) ? <span className="highlight-red">SYS</span> : ( (row.customerUpdatetask === 1) ? <span className="highlight-green">CUS</span> : "-" ) },
      sort: true,
    }, {
      dataField: 'systemSensorservice',
      text: "SensorService",
      formatter: (cell, row) => { return (row.systemSensorservice === 1) ? <span className="highlight-red">SYS</span> : ( (row.customerSensorservice === 1) ? <span className="highlight-green">CUS</span> : "-" ) },
      sort: true,
    }, {
      dataField: 'systemAnubis',
      text: "Anubis",
      formatter: (cell, row) => { return (row.systemAnubis === 1) ? <span className="highlight-red">SYS</span> : ( (row.customerAnubis === 1) ? <span className="highlight-green">CUS</span> : "-" ) },
      sort: true,
    }, {
      dataField: 'systemMxuserprefs',
      text: "MetriX1 UserPrefs",
      formatter: (cell, row) => { return (row.systemMxuserprefs === 1) ? <span className="highlight-red">SYS</span> : ( (row.customerMxuserprefs === 1) ? <span className="highlight-green">CUS</span> : "-" ) },
      sort: true,
    }
  ];

  return (

      <Container fluid className="mb-5">

        <Row>
          <Col className="panel">
            <h3>Remote Configurations</h3>
            <BootstrapTable
              keyField="id"
              data={plotData.remoteConfigs}
              columns={columns}
              filter={filterFactory()}
              classes="log-table"
              pagination={paginationFactory({ hideSizePerPage: true, sizePerPage: 15 })}
              condensed
              bordered={false}
            />
          </Col>
        </Row>
      </Container>
  );
};

export default MaintenanceRemoteConfiguration;
