import React from "react";
import axios from 'axios';
import Moment from 'react-moment';
import { Link } from "react-router-dom";
import { Container, Row, Col, Table, Spinner, Button, ListGroup } from "react-bootstrap"
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons'

import { LogMessageList } from "../../components";
import MessagesPerTimespanChart from "../../components/graphs/messagesPerTimespan"
import AddMaintenanceLogModal from "../../components/AddMaintenanceLogModal"

import * as Constants from '../../utils/constants';
import * as LogLevels from '../../utils/logLevels';
import * as EventTypes from '../../utils/eventTypes';
import 'moment-timezone';

import EventsPerTimespan from "../../components/graphs/eventsPerTimespan";
import MaintenanceLogList from "../../components/MaintenanceLogList";
import CalibrationHistoryRow from "../../components/graphs/CalibrationHistoryRow";
import SensorDataRow from "../../components/SensorDataRow";
import Contact from "../../components/Contact";
import MaintenanceTimeCol from "../../components/graphs/MaintenanceTimeCol";
import SensorDataList from "../../components/SensorDataList";
import NoServiceWarning from "../../components/NoServiceWarning";
import UnknownServiceWarning from "../../components/UnknownServiceWarning";
import { Helmet } from "react-helmet";

const GateShow = (props) => {

  const gId = props?.match?.params?.id;

  const [gateData, setGateData] = React.useState(null)
  const [seenPlotData, setSeenPlotData] = React.useState(null);
  const [eventsLastWeek, setEventsLastWeek] = React.useState(null);

  const [latestEventData, setLatestEventData] = React.useState(null);
  const [eventCountsData, setEventCountsData] = React.useState(null);

  const [showAddLogModal, setShowAddLogModal] = React.useState(false);

  const query = React.useCallback(() => {
    // Get system and customer information
    axios.get(Constants.API_BASE + 'api/gate/' + gId)
      .then(res => {
        setGateData(res.data);
      }).catch(err => console.error(err))

    // Query plot data
    axios.get(Constants.API_BASE + 'api/diag/numberEvents?gateId=' + gId)
      .then(res => {
        setSeenPlotData(res.data);
      }).catch(err => console.error(err));

    axios.get(Constants.API_BASE + 'api/diag/eventsForSystemForLast7Days?gateId=' + gId)
      .then(res => {
        setEventsLastWeek(res.data);
      }).catch(err => console.error(err));

    // Query latest events
    axios.get(Constants.API_BASE + 'api/events/latestEvents/' + gId)
      .then(res => {
        setLatestEventData(res.data);
      })
      .catch(err => {
        console.error(err);
      });

    // Query event counts
    axios.get(Constants.API_BASE + 'api/events/eventCounts/' + gId)
      .then(res => {
        setEventCountsData(res.data);
      })
      .catch(err => {
        console.error(err);
      });



  }, [gId]);

  // Use effect to query for data
  React.useEffect(() => {
    query();
  }, [query])


  let latestEventTable = null;
  if (latestEventData !== null) {
    latestEventTable = (
      <div style={{ maxHeight: "380px", overflowY: "scroll" }}>
        <Table size="sm" responsive>
          <thead>
            <tr>
              <th>Timestamp</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            {latestEventData.map((v, index) => {
              return (<tr key={"event" + index}>
                <td>{<Moment local format="DD.MM.YYYY HH:mm:ss">{v.eventTime}</Moment>}</td>
                <td align="center">{EventTypes.StringFromInt(v.eventType, v.eventSubtype)}</td>
              </tr>)
            })}
          </tbody>
        </Table>
      </div>
    );
  }

  let mutedUntil = "";
  let verifcationUntil = "";
  let contacts = null;
  let noServiceWarning = null;

  if (gateData !== null) {

    if (gateData.mutedUntil !== null) {
      const refDate = new Date()
      refDate.setHours(0, 0, 0, 0);
      const dbDate = new Date(gateData.mutedUntil)
      dbDate.setHours(0, 0, 0, 0);
      const mutedPassed = (dbDate < refDate)

      const s = {}

      if (mutedPassed) {
        s.textDecoration = 'line-through'
      } else {
        s.fontWeight = "bold"
        s.color = "red"
      }

      mutedUntil = <><Moment style={s} local format="DD.MM.YYYY">{gateData.mutedUntil}</Moment>{!mutedPassed ? ' 🔇' : ''}</>
    }

    if (gateData.inVerificationUntil != null && new Date(gateData.inVerificationUntil) > new Date()) {
      const s = { fontWeight: "bold", color: "green" };
      verifcationUntil = <span style={s}>Due to req. verification until <Moment local format="DD.MM. HH:mm">{new Date(gateData.inVerificationUntil)}</Moment></span>
    }

    if (gateData.serviceExpires === null) {
      if (gateData.CustomerId !== 2)
        noServiceWarning = <UnknownServiceWarning />

    }
    else if (new Date(gateData.serviceExpires) < new Date())
      noServiceWarning = <NoServiceWarning />

    contacts = (
      <div style={{ maxHeight: "300px", overflowY: "scroll" }}>
        <ListGroup variant="flush" className="panel-list">

          {gateData.Customer.Contacts.map((v, index) => {
            return (<ListGroup.Item key={"lgi-c-" + index}>
              <div><strong>{v.name}</strong> ({v.email}{!!v.phone ? " / " + v.phone : ""})</div>
              <div>{v.description} <span className="text-muted">(via Customer)</span></div>
            </ListGroup.Item>)
          })}

          {gateData.Location !== null && gateData.Location.Contacts.map((v, index) => {
            return (<ListGroup.Item key={"lgi-l-" + index}>
              <Contact contact={v} />
            </ListGroup.Item>)
          })}

        </ListGroup>
      </div>)
  }

  const refreshGateData = () => {
    axios.get(Constants.API_BASE + 'api/gate/' + gId)
      .then(res => {
        setGateData(res.data);
      }).catch(err => console.error(err))
  }

  return (
    <Container className="mt-3" fluid>
      <Row >
        <Col>
          <h2 className="text-center">{(gateData === null) ? <Spinner animation="border" /> : "System Overview: " + gateData.gateId}</h2>
        </Col>
      </Row>

      <Helmet>
        <title>{gateData === null ? "Loading" : gateData.gateId} - Anubis</title>
      </Helmet>

      {noServiceWarning}

      <Row className="mt-2">
        <Col xs={12} md={3} className="panel">
          <h3 align="center">General Information</h3>
          <Table size="sm" className="mt-2 mb-0">
            <tbody>
              <tr>
                <td>First/Last seen:</td>
                <td><Moment local format="DD.MM.YYYY HH:mm:ss">{gateData?.createdAt}</Moment> / <Moment local format="DD.MM.YYYY HH:mm:ss">{gateData?.lastMessage}</Moment></td>
              </tr>
              <tr>
                <td>Muted until:</td>
                <td>{mutedUntil}{verifcationUntil}</td>
              </tr>
              <tr>
                <td>Customer</td>
                <td>{gateData?.Customer?.name}</td>
              </tr>
              <tr>
                <td>Location</td>
                <td>{gateData?.Location?.name}</td>
              </tr>
              <tr>
                <td>Connectivity</td>
                <td>{gateData?.connectivity}</td>
              </tr>
              <tr>
                <td>Comment</td>
                <td>{gateData?.comment}</td>
              </tr>
              <tr>
                <td>MetriX Version</td>
                <td>{gateData?.versionMetrix}</td>
              </tr>
              <tr>
                <td>Service valid until</td>
                <td>{gateData?.serviceExpires == null ? "unknown" : <Moment local format="DD.MM.YYYY">{gateData?.serviceExpires}</Moment>}</td>
              </tr>
              <tr>
                <td>Warning Level</td>
                <td>{(gateData === null) ? "loading..." : LogLevels.GetEffectiveLogLevelString(null, gateData)}</td>
              </tr>
              <tr>
                <td colSpan={2} align="right">
                  <Button as={Link} to={"/systems/documentation/" + gId} className="mt-1">
                    Setup Doc
                  </Button>
                  <Button as={Link} to={"/systems/edit/" + gId} className="mt-1 ms-1">
                    Edit System
                  </Button>
                  <Button as={Link} to={"/customers/edit/" + gateData?.CustomerId} className="mt-1 ms-1">
                    Edit Customer
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>

        <Col xs={12} md={3} className="panel">
          <h3 align="center">Associated Contacts</h3>
          {contacts}
        </Col>

        <Col xs={12} md={6} className="panel">
          <AddMaintenanceLogModal show={showAddLogModal} systemId={gId} onHide={() => { refreshGateData(); setShowAddLogModal(false) }} />
          <h3 align="center">Maintenance Log</h3>

          <div style={{ height: "274px", maxHeight: "300px", overflowY: "scroll" }}>
            <MaintenanceLogList data={gateData === null ? [] : gateData.MaintenanceLogs} refreshDataFunc={() => refreshGateData()} />
          </div>
          <Button className="float-end me-2" onClick={() => setShowAddLogModal(true)}>Add Entry</Button>
        </Col>

      </Row>

      <Row>
        <Col xs={12} sm={3} className="panel">
          <h3 align="center">Event Counts</h3>
          {(eventCountsData === null) ? <Spinner animation="border" style={{ position: "relative", top: "15%", left: "50%" }} /> : (
            <Table size="sm" className="mt-4">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>24h</th>
                  <th>Week</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Measurement:</td>
                  <td>{eventCountsData.countsDay[0].measurement}</td>
                  <td>{eventCountsData.countsWeek[0].measurement}</td>
                </tr>
                <tr>
                  <td>Startup:</td>
                  <td>{eventCountsData.countsDay[0].startup}</td>
                  <td>{eventCountsData.countsWeek[0].startup}</td>
                </tr>
                <tr>
                  <td>Engine Init:</td>
                  <td>{eventCountsData.countsDay[0].init}</td>
                  <td>{eventCountsData.countsWeek[0].init}</td>
                </tr>
                <tr>
                  <td>Calib. Success:</td>
                  <td>{eventCountsData.countsDay[0].calibSuccess}</td>
                  <td>{eventCountsData.countsWeek[0].calibSuccess}</td>
                </tr>
                <tr>
                  <td>Calib. Failed</td>
                  <td>{eventCountsData.countsDay[0].calibFailed}</td>
                  <td>{eventCountsData.countsWeek[0].calibFailed}</td>
                </tr>
                <tr>
                  <td>Lost Device</td>
                  <td>{eventCountsData.countsDay[0].lostDevice}</td>
                  <td>{eventCountsData.countsWeek[0].lostDevice}</td>
                </tr>
                <tr>
                  <td>Lost Local</td>
                  <td>{eventCountsData.countsDay[0].lostLocal}</td>
                  <td>{eventCountsData.countsWeek[0].lostLocal}</td>
                </tr>
                <tr>
                  <td>Lost Remote</td>
                  <td>{eventCountsData.countsDay[0].lostRemote}</td>
                  <td>{eventCountsData.countsWeek[0].lostRemote}</td>
                </tr>
              </tbody>
            </Table>
          )}
        </Col>

        <MaintenanceTimeCol systemId={gId} />

        <Col xs={12} sm={6} className="panel">
          <h3 align="center"># Messages in the last 24h</h3>
          {(seenPlotData === null) ? <Spinner animation="border" style={{ position: "relative", top: "15%", left: "50%" }} /> : <MessagesPerTimespanChart data={seenPlotData} height={285} />}
        </Col>
      </Row>

      <Row>
        <Col xs={12} sm={3} className="panel">
          <h3 align="center">Recent 100 Events</h3>
          {latestEventTable}
        </Col>

        <Col xs={12} sm={9} className="panel">
          <h3 align="center">Events in the last 7 days
            <OverlayTrigger overlay={<Tooltip>Colors indicate event type. Events are grouped into 60min windows. Radius reflects event count. Y-Axis position depends only on event type and chosen arbitrarily for better visualization.</Tooltip>} placement="bottom">
              <span><FontAwesomeIcon icon={faCircleQuestion} size={"sm"} color="#00000088" style={{ marginLeft: "8px" }} /></span>
            </OverlayTrigger>
          </h3>
          {(eventsLastWeek === null) ? <Spinner animation="border" style={{ position: "relative", top: "15%", left: "50%" }} /> : <EventsPerTimespan data={eventsLastWeek} />}
        </Col>
      </Row>

      <SensorDataRow systemId={gId} height={400} />

      <CalibrationHistoryRow systemId={gId} />

      <Row>
        <LogMessageList fixedSystemId={gId} hideCustomer={true} hideSystemId={true} colClassName="panel" xs="8" inlineTitle="Log Messages" />

        <Col xs={4} className="panel">

          <SensorDataList fixedSystemId={gId} />

        </Col>
      </Row>
    </Container>
  )
}

export default GateShow;
