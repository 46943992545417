import React from "react";
import { Chart } from "react-google-charts";

function getConvertOptionsFunc(chartType) {
  return window.google &&
    window.google.charts &&
    window.google.charts[chartType]
    ? window.google.charts[chartType].convertOptions
    : null;
}

const GoogleChart = function (props) {
  const [convertedOptions, setConvertedOptions] = React.useState(null);
  const { chartType, options, ...other } = props;
  const finalOptions = convertedOptions === null ? options : convertedOptions;

  const chartEvents = [];

  if (convertedOptions == null && props.convertOptions)
    chartEvents.push({
      eventName: "ready",
      callback(Chart) {
        const func = getConvertOptionsFunc(props.chartType);
        setConvertedOptions(func(props.options));
      }
    });

  return (
    <Chart
      chartType={chartType}
      options={finalOptions}
      chartEvents={chartEvents}
      {...other}
    />
  );
};

GoogleChart.defaultProps = {
  convertOptions: false
}

export default GoogleChart;
