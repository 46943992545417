import React from "react";
import { Modal, Button, Form, ListGroup, Row, Col } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import axios from 'axios';
import NotificationModal from "./NotificationModal";
import useModal from "../utils/useModal";

import * as Constants from '../utils/constants';

const filterNames = (persons, needle) => {
    needle = needle.toLowerCase();
    const result = []
    for (let i = 0; i < persons.length; i++) {
        if (persons[i].name.toLowerCase().includes(needle))
            result.push(persons[i])
        if (result.length >= 5)
            break;
    }
    return result;
}

function AddPersonModal(props) {

    const { register, handleSubmit, errors, reset } = useForm({ defaultValues: { name: props.data?.name } })
    const { data: notificationData, setData: setNotificationData } = useModal();

    const [loading, setLoading] = React.useState(false);
    const [persons, setPersons] = React.useState(false);
    const [needle, setNeedle] = React.useState("");

    const apiRoute = (props.target === 'location') ? (Constants.API_BASE + 'api/location/' + props.id + '/contact') : (Constants.API_BASE + 'api/customer/' + props.id + '/contact');

    React.useEffect(() => {
        axios.get(Constants.API_BASE + 'api/contact')
            .then(res => {
                setPersons(res.data);
            }).catch(err => { console.warn({ title: "Failed", text: err.message }); })

    }, [])


    const resetAndClose = () => {
        setLoading(false)
        setNeedle("")
        reset();
        props.onChange();
        props.onHide()
    }

    const onCancel = () => {
        resetAndClose();
    }

    const onSubmit = async formData => {

        setLoading(true)
        try {
            const contactResponse = await axios.post(Constants.API_BASE + 'api/contact', formData)
            console.log(await axios.post(apiRoute, contactResponse.data))
            resetAndClose();
        } catch (err) {
            setNotificationData({ title: "Failed", text: err.message })
            setLoading(false);
        }
    }

    const onSelectExisting = async (contactId) => {
        setLoading(true)
        try {
            await axios.post(apiRoute, { id: contactId })
            resetAndClose();
        } catch (err) {
            setNotificationData({ title: "Failed", text: err.message })
            setLoading(false);
        }
    }

    let shownPersons = []
    if (needle === "") {
        for (let i = 0; i < persons.length; i++) {
            shownPersons.push(persons[i])

            if (shownPersons.length >= 5) break;
        }
    } else {
        shownPersons = filterNames(persons, needle)
    }

    return (
        <>
            <NotificationModal data={notificationData} />
            <Modal
                show={props.show}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
            >
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Body>
                        <h3 className="text-center">Add existing Person</h3>
                        <Form.Group className="mb-2">
                            <Form.Label>Search by name</Form.Label>
                            <Form.Control type="text" name="needle" placeholder="Enter name" onChange={(value) => setNeedle(value.target.value)} />
                        </Form.Group>

                        <ListGroup>
                            {shownPersons.map((v, index) => {
                                return (<ListGroup.Item key={"add-person" + index} className="d-flex align-items-center justify-content-between">
                                    <span>{v.name} ({v.email})</span>
                                    <Button className="float-end" size="sm" onClick={() => onSelectExisting(v.id)} disabled={loading}>Add</Button>
                                </ListGroup.Item>)
                            })}
                        </ListGroup>

                        <h3 className="text-center mt-4">Add new Person</h3>

                        <Row>
                            <Col>
                                <Form.Group className="mb-2">
                                    <Form.Label>Name *</Form.Label>
                                    <Form.Control type="text" name="name" placeholder="Name" ref={register({ required: true })} isInvalid={errors?.name} />
                                    <Form.Control.Feedback type="invalid">Please enter a name</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-2">
                                    <Form.Label>E-Mail *</Form.Label>
                                    <Form.Control type="text" name="email" placeholder="E-mail" ref={register({ required: true })} isInvalid={errors?.email} />
                                    <Form.Control.Feedback type="invalid">Please enter an e-mail</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-2">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control type="text" name="phone" placeholder="Phone number" ref={register({ required: false })} isInvalid={errors?.phone} />
                                    <Form.Control.Feedback type="invalid">Please enter a phone number</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Description / Responsibility *</Form.Label>
                                    <Form.Control type="text" name="description" placeholder="Description" ref={register({ required: true })} isInvalid={errors?.description} />
                                    <Form.Control.Feedback type="invalid">Some error occured with the description</Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group className="mb-2">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control type="text" name="address" placeholder="Quantification Avenue 42" isInvalid={errors?.address} />
                                </Form.Group>

                                <Form.Group className="mb-2">
                                    <Form.Label>Adress 2</Form.Label>
                                    <Form.Control type="text" name="address2" placeholder="optional" ref={register({ required: false })} isInvalid={errors?.address2} />
                                </Form.Group>

                                <Form.Group className="mb-2">
                                    <Form.Label>Zip Code</Form.Label>
                                    <Form.Control type="text" name="zip" placeholder="12345" ref={register({ required: false })} isInvalid={errors?.zip} />
                                </Form.Group>

                                <Form.Group className="mb-2">
                                    <Form.Label>City</Form.Label>
                                    <Form.Control type="text" name="City" placeholder="Measurementville" ref={register({ required: false })} isInvalid={errors?.city} />
                                </Form.Group>

                                <Form.Group className="mb-2">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Control type="text" name="country" placeholder="Palletopia" ref={register({ required: false })} isInvalid={errors?.country} />
                                </Form.Group>
                            </Col>
                        </Row>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="float-end" onClick={() => onCancel()} disabled={loading}>
                            Cancel
                        </Button>

                        <Button className="float-end me-2" type="submit" disabled={loading}>
                            {loading ? 'Loading...' : 'Add new Person'}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

AddPersonModal.defaultProps = {
    customer: Constants.DEFAULT_CUSTOMER,
    id: -1,
    target: "location",
    onChange: () => { }
}

export default AddPersonModal;