import React from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import { Col, Row, Button, Container, Alert } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import * as Constants from '../../utils/constants';

const Locations = (props) => {
    const [data, setData] = React.useState(null)
    const [toastDismissed, setToastDismissed] = React.useState(false)

    let toast = "";
    if (!!props.location.state?.toast && toastDismissed === false) {
        toast = (<Alert variant="success" onClose={() => setToastDismissed(true)} dismissible>{props.location.state.toast}</Alert>)
    }

    React.useEffect(() => {
        axios.get(Constants.API_BASE + 'api/customer?withLocation=true')
            .then(res => {
                setData(res.data);
            })
    }, [])

    const editButton = function (cell, row, rowIndex) {
        return (
            <>
                <Button as={Link} to={"/locations/edit/" + row.id} size="sm" className="me-1">
                    Edit
                </Button>
                <Button disabled size="sm">
                    Remove
                </Button>
            </>
        )
    }

    const nameFormatter = function (cell, row, rowIndex) {
        return (
            <Link to={"/locations/edit/" + row.id} size="sm" className="mr-1">
                {cell}
            </Link>
        )
    }

    const columns = [
        {
            dataField: 'name',
            formatter: nameFormatter,
            text: "Location",
        }, {
            dataField: 'locode',
            text: "UN/LOCODE",
        }, {
            dataField: 'id',
            isDummyField: true,
            text: "Actions",
            formatter: editButton,
            align: 'right',
            headerStyle: () => {
                return { width: "220px" };
            }
        }
    ];

    return (
        <Container>
            <h2 className="my-3 text-center">Locations</h2>
            {!!toast && (
                <Row>
                    <Col>{toast}</Col>
                </Row>
            )}
            <Row>
                <Col>
                    <p className="text-center">Use this page to manage the existing locations. New locations can be added on the respective customer panel.</p>
                </Col>
            </Row>
            {data !== null && data.map((v, index) => {
                return (
                    <Row className="mb-3" key={"customer-" + index}>
                        <Col>
                            <h3>{v.name}</h3>
                            <BootstrapTable
                                keyField="id"
                                data={v.Locations}
                                columns={columns}
                                classes="log-table"
                                condensed
                                bordered={false}
                            />
                        </Col>
                    </Row>
                )
            })}

        </Container>
    )
}

export default Locations;
