import axios from 'axios';
import React from "react";

import { Container } from 'react-bootstrap'
import NotificationModal from "../../components/NotificationModal";
import useModal from "../../utils/useModal";

import * as Constants from '../../utils/constants';
import TimeTrackForm from '../../components/TimeTrackForm';
import { useLocation } from 'react-router-dom';

const TimetrackCreate = function (props) {

    const { search } = useLocation();
    const query = new URLSearchParams(search)
    const customerId = query.get("customerId");

    const { data: notificationData, setData: setNotificationData } = useModal();

    const defaultData = {
        type: undefined,
        effortMinutes: undefined,
        project: undefined,
        description: undefined,
        email: undefined,
        uid: undefined
    }
    const saveHandler = async (formData) => {
        try {
            await axios.post(Constants.API_BASE + 'api/timetrack', formData,)

            props.history.push({
                pathname: '/customers/show/' + customerId,
                state: { toast: `Successfully created new time tracking record.` }
            });

        } catch (err) { setNotificationData({ title: "Failed", text: err.message }) }
    }

    return (
        <Container className="mt-3">
            <NotificationModal data={notificationData} />
            <TimeTrackForm default={defaultData} saveHandler={saveHandler} customerId={customerId} />
        </Container>
    );
};

export default TimetrackCreate;
