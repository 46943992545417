export function generatePassword(maxlen = 24) {
    const dict = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ01234567890";
    const MIN = 0
    const MAX = dict.length;

    let pw = "";
    const skip = [34, 39, 44, 96, 60, 62]
    for (let i = 0; i <= maxlen; i++) {
        const key = getRndInteger(MIN, MAX)
        if (skip.includes(key)) continue;
        pw = pw + dict[key]
    }
    return pw;
}

function getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
}