const subtypes = {
    0: "Ignore",
    100: "Measurement",
    200: "Startup",
    300: "Init",
    301: "License Expired",
    302: "Unlicensed Cameras",
    303: "License Expires Soon",
    304: "Calib. Expired",
    305: "Invalid Checksum",
    306: "Invalid System Time",
    307: "Invalid Database",
    400: "Calibration Success",
    500: "Calibration Failed",
    501: "Missing Calib Object",
    502: "Wrong Calib Object Size",
    503: "Invalid System Height",
    504: "Invalid Camera Distance",
    600: "Lost Device",
    701: "Camera Init Failed",
    702: "Camera Connection Lost",
    901: "Plugin Loading Error",
    902: "Plugin Connection Error",
    903: "Plugin IO Error",
    1001: "Listener Request Error",
}

const types = {
    0: "Ignore",
    1: "Measurement",
    2: "Startup",
    3: "Init",
    4: "Calibration Success",
    5: "Calibration Failed",
    6: "Lost Device",
    7: "Lost Local",
    8: "Lost Remote"
}


export function StringFromInt(type, subtype) {

    const jt = parseInt(type);
    const js = parseInt(subtype);

    if (subtype != null) {

        if(!subtypes.hasOwnProperty(js))
            return `Unknown event ${js}`;

        return subtypes[js];
    }

    if(!types.hasOwnProperty(jt))
        return `Unknown event ${jt}`;

    return types[jt];
}