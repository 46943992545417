import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

const MaintenanceEffortTable = function(props) {

    let tableJsx = "";
    if (props.data === null || props.data.length === 0) {
        tableJsx = (
            <tr>
                <td colSpan="2" className="text-center">
                    <br />no data<br />
                    <span style={{fontSize: "300%"}}>🎉</span>
                </td>
            </tr>
        )
    } else {
        tableJsx = props.data.map((v, index) => {
            return (<tr key={"effortByWeek" + index}>
                <td><Link to={"/systems/show/" + v.id}>{v.gateIdString}</Link></td>
                <td>{v.customerName}</td>
                <td>{v.totalEffort}</td>
            </tr>)
        })
    }
    

    return (
        <Table size="sm" responsive>
            <thead>
              <tr>
                <th style={{width: '30%'}}>System</th>
                <th>Customer</th>
                <th>Total Minutes</th>
              </tr>
            </thead>
            <tbody>
              {tableJsx}
            </tbody>
          </Table>        
    )
};

MaintenanceEffortTable.defaultProps = {
    data: null,
    rowKey: "some-key",
    dataKey: "cnt",
}


export default MaintenanceEffortTable;
