import React from "react";
import { Modal, Button, Form } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import axios from 'axios';

import * as Constants from '../utils/constants';

function LocationModal(props) {

    const { register, handleSubmit, errors, reset } = useForm({ defaultValues: { name: props.data?.name } })

    const [loading, setLoading] = React.useState(false);
    let header = "Add new location";
    let hint = "Enter the location name below. Ensure that the location does not already exist - there is no check for duplicates."

    if (props.data?.name) {
        header = "Edit location";
    }

    //if (props.data?.id === Constants.DEFAULT_LOCATION) showDelete = false; // The default location cannot be deleted

    const resetAndClose = () => {
        setLoading(false)
        reset();
        props.onChange();
        props.onHide()
    }

    const onCancel = () => {
        resetAndClose();
    }

    const onSubmit = data => {

        const formData = {
            CustomerId: props.customer,
            name: data.name,
            locode: data.locode
        }

        setLoading(true)


        if (props.data?.name === undefined) {
            // if undefined, then we create a new location
            axios.post(Constants.API_BASE + 'api/location', formData)
                .then(res => {
                    resetAndClose();
                }).catch(err => { alert(err); setLoading(false); })
        } else {
            // else, we update an existing location
            axios.put(Constants.API_BASE + 'api/location/' + props.data.id, formData)
                .then(res => {
                    resetAndClose();
                }).catch(err => { alert(err); setLoading(false); })
        }
    }



    return (

        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
        >
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <h3 className="text-center">{header}</h3>
                    <p className="text-center">
                        {hint}
                    </p>

                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" name="name"
                            placeholder="Enter location name..."
                            ref={register({ required: true })}
                            isInvalid={errors?.name}
                            defaultValue={props.data?.name}
                        />
                        <Form.Control.Feedback type="invalid">Please enter a name</Form.Control.Feedback>
                    </Form.Group>


                    <Form.Group className="mt-3">
                        <Form.Label>UN/LOCODE from <a href="https://unece.org/trade/cefact/unlocode-code-list-country-and-territory"  rel="noreferrer" target="_blank">open UNECE Website</a></Form.Label>
                        <Form.Control type="text" name="locode"
                            placeholder="Enter UN/LOCODE..."
                            ref={register({ required: true })}
                            isInvalid={errors?.locode}
                            defaultValue={props.data?.locode}
                        />
                        <Form.Control.Feedback type="invalid">Please enter the UN/LOCODE</Form.Control.Feedback>
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button className="float-end me-2" type="submit" disabled={loading}>
                        {loading ? 'Loading...' : 'Save'}
                    </Button>

                    <Button className="float-end" onClick={() => onCancel()} disabled={loading}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

LocationModal.defaultProps = {
    customer: Constants.DEFAULT_CUSTOMER,
    data: {
        id: undefined,
        name: undefined
    },
    onChange: () => { }
}

export default LocationModal