import React from "react";
import MetrixSpinner from "../assets/images/metrix-spinner.svg";

const Loading = () => (
  <div className="spinner">
    <div  style={{position: "relative", marginBottom: "20px"}}>
      <img src={MetrixSpinner} alt="React Logo" style={{width: 144, height: 144, marginBottom: 20}} />  
      <h3>Loading</h3>
    </div>    
  </div>
);

export default Loading;
