import React from "react";
import axios from 'axios';

import { Row, Col, Form, Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Constants from '../utils/constants';
import { useHistory } from 'react-router-dom';
import Moment from 'react-moment';
import CameraModal from "./cameraModal";
import NotificationModal from "./NotificationModal";
import useModal from "../utils/useModal";


const GateFormComponent = function (props) {

    const gId = props.id;
    const [gateData, setGateData] = React.useState(null);
    const [customerData, setCustomerData] = React.useState(null);
    const [locations, setLocations] = React.useState([])
    const [cameras, setCameras] = React.useState([])
    const [cameraModalShow, setCameraModalShow] = React.useState(null);
    const history = useHistory();
    const {data: notificationData, setData: setNotificationData} = useModal();

    const { register, handleSubmit, errors } = useForm();

    React.useEffect(() => {
        axios.get(Constants.API_BASE + 'api/gate/' + gId)
            .then(res => {
                axios.get(Constants.API_BASE + 'api/location?CustomerId=' + res.data.CustomerId)
                    .then(locationRes => {
                        // FIRST: set locations to make the system's default value available
                        setLocations(locationRes.data);
                        setGateData(res.data);
                    }).catch(err => console.error(err))
            }).catch(err => console.error(err))

        axios.get(Constants.API_BASE + 'api/customer')
            .then(res => {
                setCustomerData(res.data);
            }).catch(err => console.error(err))

        axios.get(Constants.API_BASE + 'api/camera/' + gId)
            .then(res => {
                setCameras(res.data)
            })
            .catch(err => console.error(err))

    }, [gId])

    if (!gId) {
        console.error("Gate ID is not set.")
        return "gate id is not set";
    }

    if (gateData === null || customerData === null) {
        return "loading...";
    }

    const saveHandler = (formData) => {

        formData.active = (formData.active) ? 1 : 0;

        axios.put(Constants.API_BASE + 'api/gate/' + gId, formData)
            .then(res => {
                console.log(res);

                history.push({
                    pathname: '/systems',
                    state: { toast: `Settings for system '${formData.gateId}' have been saved.` }
                });

            }).catch(err => {
                setNotificationData({title: "Update Failed", text: err.message})
                console.error(err)
            })
    }

    const updateLocations = async (evt) => {
        const value = evt.target.value
        try {
            axios.get(Constants.API_BASE + 'api/location?CustomerId=' + value)
                .then(locationRes => { setLocations(locationRes.data) })
        } catch (err) {
            console.warn(err)
        }
    }

    const toggleCamerasHandler = () => {
        try {
            axios.get(Constants.API_BASE + 'api/camera/' + gId)
                .then(res => { setCameras(res.data) })
        } catch (err) {
            console.warn(err)
        }
    }

    return (
        <>
            <CameraModal show={cameraModalShow !== null} data={cameraModalShow} onHide={() => setCameraModalShow(null)} onFinish={() => toggleCamerasHandler()} />
            <NotificationModal data={notificationData} />

            <Row>
                <Col>
                    {(props.header !== null && <h2 className="text-center">{props.header}</h2>)}
                    <Button as={Link} to={"/systems/show/" + gId} className="float-end clearfix mb-3">
                        Show System
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form onSubmit={handleSubmit(saveHandler)} >
                        <Form.Group className="mb-3">
                            <Form.Label>System ID</Form.Label>
                            <Form.Control type="text" name="gateId" readOnly ref={register({ required: true })} defaultValue={gateData?.gateId} />
                            <Form.Control.Feedback type="invalid">Please enter a name</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Customer</Form.Label>
                            <Form.Control as="select" name="CustomerId" ref={register({ required: true })} defaultValue={gateData?.CustomerId} onChange={(evt) => updateLocations(evt)}>
                                {customerData.map((c) =>
                                    <option value={c.id} key={c.id}>{c.name}</option>
                                )}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">Please select a level</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Location</Form.Label>
                            <Form.Control as="select" name="LocationId" ref={register({ required: true })} defaultValue={gateData?.LocationId} isInvalid={errors.LocationId}>
                                <option value="" key={0}>None</option>
                                {locations.map((c) =>
                                    <option value={c.id} key={c.id}>{c.name}</option>
                                )}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">Please select a location</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Connectivity</Form.Label>
                            <Form.Control type="text" name="connectivity" ref={register({ required: false })} defaultValue={gateData?.connectivity} />
                            <Form.Text className="text-muted">
                                Provide information about the connectivity of the system here, e.g. whether it has internet access via a LTE stick.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Check
                                type="switch"
                                id="switch-active"
                                name="active"
                                label="System is active (considered in evaluation)"
                                ref={register()}
                                defaultChecked={(gateData?.active === 1) ? true : false}
                            />
                            <Form.Text className="text-muted">
                                Inactive systems are not shown or considered in various places, e.g. the last seen box on the home screen or the camera evaluation in the maintenance view.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Notification Level</Form.Label>
                            <Form.Control as="select" name="notificationLevel" ref={register({ required: true })} defaultValue={gateData?.notificationLevel}>
                                <option value="0">DEFAULT (use customer setting)</option>
                                <option value="10">DEBUG</option>
                                <option value="20">INFO</option>
                                <option value="30">WARN</option>
                                <option value="40">ERROR</option>
                                <option value="50">FATAL</option>
                                <option value="99">NONE</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">Please select a level</Form.Control.Feedback>
                            <Form.Text className="text-muted">
                                Specify a notification level for this system. This setting overrides the customer's notification level. Example: the customer's level is set to 'WARN' and the system's level is set to 'INFO'.
                                The log server will now send notifications for this gate at INFO level and WARN level notifications for all other systems of this customer.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Mute System until</Form.Label>
                            <Form.Control type="date" name="mutedUntil" ref={register()} placeholder="some date" defaultValue={gateData?.mutedUntil} />
                            <Form.Text className="text-muted">
                                Prevent the system from causing warnings in Mattermost until this date (inclusively). This date is evaluated as UTC 00:00:00.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Service Contract Expiration Date</Form.Label>
                            <Form.Control type="date" name="serviceExpires" ref={register()} placeholder="some date" defaultValue={gateData?.serviceExpires} />
                            <Form.Text className="text-muted">
                                The expiration of the current service contract.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Comment</Form.Label>
                            <Form.Control as="textarea" name="comment" rows="3" ref={register()} placeholder="Enter an optional comment..." defaultValue={gateData?.comment} />
                        </Form.Group>
                        <Button variant="primary" className="float-end" type="submit">
                            Save
                        </Button>
                    </Form>
                </Col>
            </Row>

            <Row className="mt-3 mb-3">
                <Col>
                    <h3 align="center" className="mt-2">Connected Cameras</h3>
                    <Table size="sm" responsive>
                        <thead>
                            <tr>
                                <th>Serial</th>
                                <th>Comment</th>
                                <th>Carlibration Success %</th>
                                <th>Calibration Count</th>
                                <th>First Seen</th>
                                <th>Last Seen</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {cameras.map((v, index) => {

                                const btnTxt = (v.decommissioned === 1) ? "Decommissioned" : "Active";
                                const btnVariant = (v.decommissioned === 1) ? "outline-danger" : "outline-success";
                                const btnData = {
                                    serialNr: v.SerialNr,
                                    comment: v.comment,
                                    decommissioned: v.decommissioned
                                }

                                return (
                                    <tr key={"cameras" + index}>
                                        <td>{v.SerialNr}</td>
                                        <td>{v.comment}</td>
                                        <td>{v.successPct}</td>
                                        <td>{v.calibCount}</td>
                                        <td>{(v.firstDate === null) ? "unknown" : <Moment local format="DD.MM.YYYY HH:mm:ss">{v.firstDate}</Moment>}</td>
                                        <td>{(v.lastDate === null) ? "unknown" : <Moment local format="DD.MM.YYYY HH:mm:ss">{v.lastCalibration}</Moment>}</td>
                                        <td>
                                            <Button variant={btnVariant} className="float-end" size="sm" type="submit" onClick={() => setCameraModalShow(btnData)}>{btnTxt} ⚙️</Button>
                                        </td>
                                    </tr>)
                            })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    );
};

GateFormComponent.defaultProps = {
    header: null
}

export default GateFormComponent;
