import React from "react";
import 'moment-timezone';
import moment from 'moment'
import * as Constants from '../../utils/constants';

import {
  LineChart, Line, Label, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip, Legend } 
  from 'recharts';

const CalibrationMetricChart = function (props) {

  const formatAsDate = (tick) => {
    return moment(tick).format('DD.MM.YY')
  };

  const serials = props.data.serials;
  const plotData = props.data.plotData;

  if(plotData.length === 0) {
    return (
      <ResponsiveContainer width="100%" height={props.height }>
        <LineChart data={plotData} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="ts" type="number" domain={[0,1]}/>
          <YAxis type="number" domain={[0,1]} yAxisId="left">
            <Label angle={-90} value={props.yLeftLabel} position='insideLeft' style={{ textAnchor: 'middle' }} />
          </YAxis>
        </LineChart>
      </ResponsiveContainer>
    );
  }

  return (
    <ResponsiveContainer width="100%" height={props.height }>
      <LineChart data={plotData} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
        <CartesianGrid strokeDasharray="3 3" />

        <XAxis dataKey="ts" type="number" scale="time" domain={[plotData[0]['ts'], plotData[plotData.length-1]['ts']]} tickFormatter={formatAsDate} />
        <YAxis type="number" domain={['dataMin - 2', 'dataMax + 2']} yAxisId="left" tickFormatter={(v) => v.toFixed(2)}>
          <Label angle={-90} value={props.yLeftLabel} position='insideLeft' style={{ textAnchor: 'middle' }} />
        </YAxis>
        
        {(props.rightKey !== null) && (
        <YAxis type="number" domain={['dataMin - 2', 'dataMax + 2']} yAxisId="right" orientation="right" tickFormatter={(v) => v.toFixed(2)}>
          <Label angle={-90} value={props.yRightLabel} position='insideRight' style={{ textAnchor: 'middle' }} />
        </YAxis>
        )}

        {(props.scatterFailed === true) && (
          <YAxis type="number" domain={[0.5, 1.5]} yAxisId="right" orientation="right" tickFormatter={(v) => ""} axisLine={false} tickLine={false}></YAxis>
        )}
        <Tooltip labelFormatter={formatAsDate} />
        <Legend />
        
        {serials.map((v, index) => {
          return (
            <Line isAnimationActive={false} yAxisId="left" type="linear" name={v + props.leftName} key={v+Math.floor(Math.random() * 1e10)} dataKey={v + props.leftKey} stroke={Constants.CONTRAST_COLORS[index % Constants.PLOT_COLORS.length]} strokeWidth={2} dot={false} />
          )
        })}
        
        {serials.map((v, index) => {
          if (props.rightKey === null) return null;
          else {
            return (
              <Line isAnimationActive={false} yAxisId="right" type="linear" name={v + props.rightName} key={v+Math.floor(Math.random() * 1e10)} dataKey={v + props.rightKey} stroke={Constants.CONTRAST_COLORS[index % Constants.PLOT_COLORS.length]} strokeWidth={2} strokeDasharray="1 1" dot={false} />
            )
          }
        })}

        {(props.scatterFailed===true) && (
          <Line isAnimationActive={false} yAxisId="right" name="failed" dataKey="failed" stroke="#ff0000" strokeWidth={2} legendType="dot" dot={{ stroke: 'red', strokeWidth: 5, r: 1,strokeDasharray:''}} />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};

CalibrationMetricChart.defaultProps = {
  height: 250,
  yLeftLabel: 'Missing Pixels %',
  yRightLabel: 'Missing Pixels ROI %',
  leftKey: " invalidRoi",
  rightKey: " invalid",
  leftName: "",
  rightName: " ROI",
  scatterFailed: false
}

export default CalibrationMetricChart;