import axios from 'axios';
import React from "react";
import { Button, Container, Table } from 'react-bootstrap'
import useModal from "../../utils/useModal";
import { useLocation } from 'react-router-dom';
import * as Constants from '../../utils/constants';
import LoadingSpinner from '../../components/LoadingSpinner';
import Moment from 'react-moment';
import NotificationModal from '../../components/NotificationModal';

const TimetrackDelete = function (props) {

    const { search } = useLocation();
    const query = new URLSearchParams(search)
    const customerId = query.get("customerId");
    const [data, setData] = React.useState(null);
    const { data: notificationData, setData: setNotificationData } = useModal();
    const recordId = props?.match?.params?.id;


    React.useEffect(() => {
        axios.get(Constants.API_BASE + 'api/timetrack/' + recordId).then(res => setData(res.data)).catch(err => console.error(err))
    }, [recordId])

    const deleteHandler = async (formData) => {
        try {
            await axios.delete(Constants.API_BASE + 'api/timetrack/' + recordId)

            props.history.push({
                pathname: '/customers/show/' + customerId,
                state: { toast: `Successfully deleted time tracking record.` }
            });

        } catch (err) { setNotificationData({ title: "Failed", text: err.message }) }
    }

    if (data === null) {
        return <LoadingSpinner text="Loading time tracking record..." />
    }

    if(customerId === null || customerId === undefined) {
        return <p>ERROR: MISSING CUSTOMER ID</p>
    }

    return (
        <Container className="mt-3">
            <NotificationModal data={notificationData} />
            <h2 className='text-center'>Delete Record</h2>

            <p className='text-center'>Please confirm that you would like to delete the following record:</p>

            <Table size="sm" responsive>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Person</th>
                        <th>Project</th>
                        <th>Type</th>
                        <th>Description</th>
                        <th>Time</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><Moment local format="DD.MM.YYYY">{new Date()}</Moment></td>
                        <td>{data.email}</td>
                        <td>{data.project}</td>
                        <td>{Constants.Map_TimetrackTypeNumeric_TimetrackTypeString[data.type]}</td>
                        <td>{data.description === "" ? <span>-</span> : data.description}</td>
                        <td>{data.effortMinutes}</td>
                    </tr>
                </tbody>
            </Table>
            <Button variant='danger' className='float-end' onClick={() => deleteHandler()}>Delete</Button>
        </Container>
    );
};

export default TimetrackDelete;
