import axios from 'axios';
import React from "react";
import { Row, Col, Form, Button, ListGroup } from "react-bootstrap";
import Moment from 'react-moment'
import { useForm } from "react-hook-form";
import * as Constants from '../utils/constants';
import BinaryDescisionModal from './BinaryDescisionModal';
import NotificationModal from "../components/NotificationModal";
import useModal from "../utils/useModal";

const XpuFormComponent = function (props) {

    const { register, handleSubmit, errors, setError } = useForm();
    const [gateAssociation, setGateAssociation] = React.useState(null);
    const [gateData, setGateData] = React.useState(null);
    const [xpuData, setXpuData] = React.useState(null)
    const [deleteModal, setDeleteModal] = React.useState(null);
    const {data: notificationData, setData: setNotificationData} = useModal()

    React.useEffect(() => {
        axios.get(Constants.API_BASE + 'api/gate')
            .then((result => setGateData(result.data)))
            .catch(err => (alert("Could not load gate data. Seek help." + err)))

        if (props.xpuId != null) {
            axios.get(Constants.API_BASE + 'api/xpu/' + props.xpuId)
                .then(res => {
                    console.log("xpu data", res.data)
                    setXpuData(res.data);
                })
        }
    }, [props.xpuId])

    const refreshXpuData = () => {
        axios.get(Constants.API_BASE + 'api/xpu/' + props.xpuId)
            .then(res => {
                setXpuData(res.data);
            })
    }

    const addGateAssociationHandler = () => {
        axios.post(Constants.API_BASE + 'api/xpu/' + props.xpuId + '/gate', { id: gateAssociation })
            .then(res => {
                console.log("addGateResponse", res);
                refreshXpuData()
            }).catch(err => setNotificationData({title: "Update Failed", text: err.message}))
    }

    const removeGateAssociationHandler = () => {

        axios.delete(Constants.API_BASE + 'api/xpu/' + props.xpuId + '/gate/' + deleteModal?.systemId)
            .then(res => {
                console.log("removeGateResponse", res);
                refreshXpuData()
            }).catch(err => setNotificationData({title: "Update Failed", text: err.message}))

        setDeleteModal(null);
    }

    const defaultSelect = "defaultSelect";

    const onSubmit = data => {

        let isError = false;

        if (data.GateId === defaultSelect && props.create === true) {
            setError('GateId', { type: 'custom', message: 'custom message' });
            isError = true;
        }

        if (data.systemType === defaultSelect) {
            setError('systemType', { type: 'custom', message: 'custom message' });
            isError = true;
        }

        if (isError === false)
            props.saveHandler(data);
        else
            console.log("form errors: ", errors)
    }

    if (gateData === null)
        return "loading..."

    if (props.create === false && xpuData === null)
        return "loading..."

    return (
        <>
            <NotificationModal data={notificationData} />
            <Row>
                <Col>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className="mb-2">
                            <Form.Label>XPU ID</Form.Label>
                            <Form.Control type="text" name="name" placeholder="Enter XPU ID..." ref={register({ required: true })} isInvalid={errors?.name} defaultValue={xpuData?.name && xpuData?.name} />
                            <Form.Control.Feedback type="invalid">Please enter a host id, e.g. XPU-123</Form.Control.Feedback>
                        </Form.Group>

                        {props.create && (
                            <Form.Group className="mb-3">
                                <Form.Label>System ID</Form.Label>
                                <Form.Control as="select" name="GateId" ref={register({ required: "select system" })} isInvalid={errors?.GateId} >
                                    <option defaultValue hidden value={defaultSelect}>Select SystemId for this XPU unit.</option>
                                    {gateData.map((c) =>
                                        <option value={c.id} key={"gate-" + c.id}>{c.gateId} - {c.Customer.name} {c.Location === null ? " in unknown location" : " in " + c.Location.name}</option>
                                    )}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">Please associate this XPU with an existing system</Form.Control.Feedback>
                            </Form.Group>
                        )}

                        <Form.Group className="mb-2">
                            <Form.Label>System Type</Form.Label>
                            <Form.Control as="select" name="systemType" ref={register({ required: "select system" })} isInvalid={errors?.systemType} defaultValue={xpuData !== null ? xpuData.systemType : defaultSelect}>
                                <option hidden value={defaultSelect}>Select a system type</option>

                                <option value="S110">S110</option>
                                <option value="S120">S120</option>
                                <option value="S140">S140</option>
                                <option disabled>──────────</option>

                                <option value="L100">L100</option>
                                <option value="L130">L130</option>
                                <option value="L135">L135</option>
                                <option disabled>──────────</option>

                                <option value="L200">L200</option>
                                <option value="L205">L205</option>

                                <option value="L230">L230</option>
                                <option value="L235">L235</option>
                                <option disabled>──────────</option>

                                <option value="CUS">Custom</option>

                            </Form.Control>
                            <Form.Control.Feedback type="invalid">Please provide a system type.</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-2">
                            <Form.Check
                                type="switch"
                                id="switch-active"
                                name="decommissioned"
                                label="Decommissioned"
                                ref={register()}
                                defaultChecked={xpuData !== null ? xpuData.decommissioned : false}
                            />
                            <Form.Text className="text-muted">
                                Check if this system is not in use anymore.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-2">
                            <Form.Label>Comment</Form.Label>
                            <Form.Control as="textarea" name="comment" rows="3" ref={register()} placeholder="Enter an optional comment, e.g. that this XPU is the remote XPU of a particular system" defaultValue={xpuData?.comment && xpuData?.comment} />
                        </Form.Group>

                        <Button variant="primary" className="float-end mt-1" type="submit">
                            Save
                        </Button>
                    </Form>
                </Col>
            </Row>

            {props.create === false && (
                <Row>
                    <Col>
                        <BinaryDescisionModal yes={() => removeGateAssociationHandler()} no={() => setDeleteModal(null)} title={"Remove " + deleteModal?.systemName} text={"Are you sure that you want to remove the association to " + deleteModal?.systemName + " for this XPU"} show={deleteModal !== null} />
                        <h4 className="text-center mt-3">Manage System Associations</h4>
                        <ListGroup>
                            {xpuData != null && xpuData.Gates.map((c, i) => {

                                return (<ListGroup.Item key={"xpu-a-" + i}>
                                    <Button size="sm" className="float-end" onClick={() => setDeleteModal({ systemId: c.id, systemName: c.gateId })}>Remove</Button><strong>{c.gateId}</strong><br />
                                    {c.Customer.name} {c.Location === null ? " in unknown location" : " in " + c.Location.name}<br />
                                    <span className="text-muted">First Seen: </span><Moment local format="DD.MM.YYYY HH:mm:ss">{c.createdAt}</Moment> <span className="text-muted">Last Message: </span><Moment local format="DD.MM.YYYY HH:mm:ss">{c.lastMessage}</Moment>
                                </ListGroup.Item>)
                            })}
                        </ListGroup>
                    </Col>
                    <Col>
                        <h4 className="text-center mt-3">Add new Association</h4>
                        <Form.Group className="">
                            <Form.Control as="select" name="GateId" onChange={(evt) => setGateAssociation(evt.target.value)}>
                                {gateData.map((c) =>
                                    <option value={c.id} key={"gate-" + c.id}>{c.gateId} - {c.Customer.name} {c.Location === null ? " in unknown location" : " in " + c.Location.name}</option>
                                )}
                            </Form.Control>
                        </Form.Group>

                        <Button variant="primary" className="float-end mt-1" onClick={() => addGateAssociationHandler()}>
                            Add
                        </Button>
                    </Col>
                </Row>
            )}
        </>
    );
};

XpuFormComponent.defaultProps = {
    create: true,
    xpuId: null
}

export default XpuFormComponent;
