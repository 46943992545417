import React from "react";
import axios from 'axios';
import Moment from 'react-moment';
import { Col, Spinner } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import * as Constants from '../utils/constants';

import 'moment-timezone';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const defaultShownPerPage = 50;

const getFetchParams = (fixedSystemId, newState) => {
    let params = {
        page: 1,
        count: defaultShownPerPage,
        systemId: fixedSystemId,
    }

    if (newState !== null) {
        params.page = newState.page;
    }

    return params;
}

const updateMinMax = (newState, minKey, maxKey, value) => {
    if(newState[minKey] > value) {
        newState[minKey] = value;
    }
    if(newState[maxKey] < value) {
        newState[maxKey] = value;
    }
}

const getColors = (cell, minVal, maxVal) =>{
    const p = (cell - minVal) / (maxVal-minVal);
    const pct = Math.round(p * 100)
    let red = 1
    let green = 1;
    const fac = p*2
    if(fac <= 1) 
        green = fac
    else
        red = 1-(fac - 1.0)
    green = Math.round(green*255)
    red = Math.round(red*255)
    return {pct,green,red}
}

const fetchData = (fixedSystemId, setTableProps, newState) => {

    const params = getFetchParams(fixedSystemId, newState);

    axios.get(`${Constants.API_BASE}api/sensor`, {
        params: params,
    })
        .then(res => {
            //const newState = { ...tableProps };
            //console.log(res.data)
            const newState = {
                page: params.page,
                data: res.data.rows,
                dataCount: 5000,

                hasHdd: false,
                hasMb1: false,
                hasMb2: false,
                hasRam: true,
                
                maxCpuLoad: 100,
                maxCpuClock: 3500,
                maxCpuTemp: 95,
                maxMb1Temp: 75,
                maxMb2Temp: 95,
                maxHddTemp: 75,
                maxRam: 100,
                
                minCpuLoad: 0,
                minCpuClock: 800,
                minCpuTemp: 45,
                minMb1Temp: 30,
                minMb2Temp: 30,
                minHddTemp: 15,
                minRam: 0
            }

            newState.data.forEach(row => {

                updateMinMax(newState, "minCpuClock", "maxCpuClock", row.cpuClock);

                if (row.hddTemp != null) {
                    newState.hasHdd = true;
                }
                if (row.mb1Temp != null) {
                    newState.hasMb1 = true;
                }
                if (row.mb2Temp != null) {
                    newState.hasMb2 = true;
                }
            })

            setTableProps(newState)
        })
        .catch(err => {
            console.log(err);
        })
}

const SensorDataList = (props) => {

    const [tableProps, setTableProps] = React.useState({
        page: 1,
        data: null,
        dataCount: 0
    });

    const fixedSystemId = (!!props.fixedSystemId) ? props.fixedSystemId : null;

    const fetchCallback = React.useCallback(() => {
        fetchData(fixedSystemId, setTableProps, null);
    }, [fixedSystemId]);

    React.useEffect(() => {
        fetchCallback()
    }, [fetchCallback])

    const handlePageChange = function (page, sizePerPage) { }

    const onTableChange = (type, newState) => {
        //fetchData(newState);
        fetchData(fixedSystemId, setTableProps, newState)
    }

    const columns = []

    columns.push({
        dataField: 'timestamp', text: 'Date',
        formatter: (cell, row) => {
            return (<Moment local format="DD.MM.YY HH:mm:ss">{new Date(row.ts * 1000)}</Moment>)
        },
        headerStyle: () => { return { width: "130px" }; }
    });

    columns.push({ dataField: 'cpuLoad', text: 'Load', style: (cell, row, rowIndex, colIndex) => {
        const {pct, green, red} = getColors(cell, tableProps.minCpuLoad, tableProps.maxCpuLoad)
        return {"background": `linear-gradient(90deg, rgba(${red},${green},0,1) 0%, rgba(${red},${green},0,1) ${pct}%, rgba(0,0,0,0) ${pct}%)`}
    }});

    if (tableProps.hasRam) columns.push({ dataField: 'ramUsage', text: 'RAM %', style: (cell, row, rowIndex, colIndex) => {
        const {pct, green, red} = getColors(cell, tableProps.minRam, tableProps.maxRam)
        return {"background": `linear-gradient(90deg, rgba(${green},${red},0,1) 0%, rgba(${green},${red},0,1) ${pct}%, rgba(0,0,0,0) ${pct}%)`}
    }});

    columns.push({ dataField: 'cpuClock', text: 'Clock', style: (cell, row, rowIndex, colIndex) => {
        const {pct, green, red} = getColors(cell, tableProps.minCpuClock, tableProps.maxCpuClock)
        return {"background": `linear-gradient(90deg, rgba(${red},${green},0,1) 0%, rgba(${red},${green},0,1) ${pct}%, rgba(0,0,0,0) ${pct}%)`}
    }});

    columns.push({ dataField: 'cpuTemp', text: 'CPU°', style: (cell, row, rowIndex, colIndex) => {
        const {pct, green, red} = getColors(cell, tableProps.minCpuTemp, tableProps.maxCpuTemp)
        return {"background": `linear-gradient(90deg, rgba(${green},${red},0,1) 0%, rgba(${green},${red},0,1) ${pct}%, rgba(0,0,0,0) ${pct}%)`}
    }});

    if (tableProps.hasMb1) columns.push({ dataField: 'mb1Temp', text: 'MB1°', style: (cell, row, rowIndex, colIndex) => {
        const {pct, green, red} = getColors(cell, tableProps.minMb1Temp, tableProps.maxMb1Temp)
        return {"background": `linear-gradient(90deg, rgba(${green},${red},0,1) 0%, rgba(${green},${red},0,1) ${pct}%, rgba(0,0,0,0) ${pct}%)`}
    }});
    
    if (tableProps.hasMb1) columns.push({ dataField: 'mb2Temp', text: 'MB2°', style: (cell, row, rowIndex, colIndex) => {
        const {pct, green, red} = getColors(cell, tableProps.minMb2Temp, tableProps.maxMb2Temp)
        return {"background": `linear-gradient(90deg, rgba(${green},${red},0,1) 0%, rgba(${green},${red},0,1) ${pct}%, rgba(0,0,0,0) ${pct}%)`}
    }});

    if (tableProps.hasHdd) columns.push({ dataField: 'hddTemp', text: 'HDD°', style: (cell, row, rowIndex, colIndex) => {
        const {pct, green, red} = getColors(cell, tableProps.minHddTemp, tableProps.maxHddTemp)
        return {"background": `linear-gradient(90deg, rgba(${green},${red},0,1) 0%, rgba(${green},${red},0,1) ${pct}%, rgba(0,0,0,0) ${pct}%)`}
    }});


    const pageOptions = {
        onPageChange: handlePageChange,
        page: tableProps.page,
        sizePerPage: defaultShownPerPage,
        totalSize: tableProps.dataCount,
        hideSizePerPage: true,
    }

    return (
        <Col className={props.colClassName} xs={props.xs}>
            {(props.header !== null) && <h3 style={{ float: "left" }}>{props.header}</h3>}

            {(tableProps.data === null) ? (<Spinner animation="border" style={{ position: "relative", top: "5%", left: "50%", marginTop: "100px" }} />) : (
                <PaginationProvider pagination={paginationFactory(pageOptions)}>
                    {
                        ({
                            paginationProps,
                            paginationTableProps
                        }) => (
                            <>
                                <h2 style={{marginBottom:0, marginTop: 8, float:"left"}}>Sensor Data</h2>
                                <PaginationListStandalone
                                    {...paginationProps}
                                />
                                <BootstrapTable
                                    keyField="ts"
                                    data={tableProps.data}
                                    columns={columns}
                                    filter={filterFactory()}
                                    classes="log-table"
                                    bordered={false}
                                    condensed
                                    remote
                                    onTableChange={onTableChange}
                                    {...paginationTableProps}
                                />
                            </>
                        )
                    }
                </PaginationProvider>
            )}
        </Col>
    )

}

SensorDataList.defaultProps = {
    fixedSystemId: null,
    hideCustomer: false,
    hideSystemId: false,
    colClassName: "",
    header: null,
    xs: 12
}


export default SensorDataList;
