import React from "react";
import { Col, Spinner } from 'react-bootstrap'
import axios from 'axios';
import { API_BASE } from '../utils/constants';

import GoogleChart from "./graphs/googleChart";

const ToGoogleChartFormat = (data) => {
    const out = []
    out.push(['Time', 'Errors', 'Muted Errors', 'Warnings', 'Muted Warnings'])

    for(let i=0; i < data.length; i++) {
        const d = data[i];

        out.push([
            new Date(d.timeblock),
            parseInt(d.errors) - parseInt(d.mutedErrors),
            parseInt(d.mutedErrors),
            parseInt(d.warnings) - parseInt(d.mutedWarnings),
            parseInt(d.mutedWarnings)
        ]);
    }
    return out;
}

function NumberMessages24hPanel(props) {

    const [data, setData] = React.useState(null);

    React.useEffect(() => {
        axios.get(API_BASE + 'api/diag/numberOfMessages24h')
            .then(res => setData(res.data))
            .catch(err => console.warn(err))
    }, [])

    if(data === null) {
        return <Spinner animation="border" style={{ position: "relative", top: "25%", left: "50%" }} />
    }

    const plotData = ToGoogleChartFormat(data)

    const gchartOptions = {
        chart: {
          title: "Population of Largest U.S. Cities",
        },
        hAxis: {
          format: 'HH:mm',
        },
        vAxis: {
          format:'#',
        },
        bars: "vertical",
        axes: {
          y: {
            0: { side: "right" },
          },
        },
        isStacked: true,
        legend: { position: 'bottom', maxLines: 3 },
        backgroundColor: "transparent",
        chartArea:{width:'90%',height:'75%', top: 10, bottom: 42, left: 30, right:20, backgroundColor: "transparent"},
        fontSize: 12,
        fontName: "'Segoe UI', Roboto",
        colors: ['#D00000', '#cc8989', '#f7d90f', '#d9cb8d']
      };

    return (
        <Col xs={12} md={8} className="panel">
            <h3>#unpleasant messages in the last 24h</h3>
            <GoogleChart 
                chartType="ColumnChart"
                width="100%"
                height="300px"
                data={plotData}
                options={gchartOptions}
            />
        </Col>
    );
}

export default NumberMessages24hPanel;