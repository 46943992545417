import React from "react";
import { Modal, Button, Form } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import axios from 'axios';
import NotificationModal from "./NotificationModal";
import useModal from "../utils/useModal";


import * as Constants from '../utils/constants';

function CameraModal(props) {

    const { register, handleSubmit, errors, reset } = useForm({ defaultValues: { name: props.data?.name } })

    const [loading, setLoading] = React.useState(false);
    const {data: notificationData, setData: setNotificationData} = useModal();

    const { onFinish, ...subProps } = props;

    let header = `Update Properties of '${props.data?.serialNr}'`;

    const resetAndClose = () => {
        setLoading(false)
        reset();
        onFinish();
        props.onHide()
    }

    const onCancel = () => {
        resetAndClose();
    }

    const onSubmit = data => {

        data.serialNr = props.data.serialNr;
        setLoading(true)

        // no update process
        axios.post(Constants.API_BASE + 'api/camera/' + props.data.serialNr, data)
            .then(res => resetAndClose())
            .catch(err => { setNotificationData({title: "Update Failed", text: err.message}); setLoading(false); })

    }

    return (
        <>
            <NotificationModal data={notificationData} />
            <Modal
                {...subProps}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
            >
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Body>
                        <h3 className="text-center">{header}</h3>
                        <Form.Group>
                            <Form.Label>Helpful information for this camera or why it is not used anymore:</Form.Label>
                            <Form.Control type="text" name="comment"
                                placeholder="Enter helpful comment..."
                                ref={register({ required: false })}
                                isInvalid={errors?.comment}
                                defaultValue={props.data?.comment}
                                as="textarea"
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Check
                                type="radio"
                                label="Active: camera is in use."
                                name="decommissioned"
                                value={0}
                                ref={register({ required: false })}
                                defaultChecked={props.data?.decommissioned === 0}
                            />
                            <Form.Check
                                type="radio"
                                label="Decommissioned: enable if the camera is not used anymore."
                                name="decommissioned"
                                value={1}
                                ref={register({ required: false })}
                                defaultChecked={props.data?.decommissioned === 1}
                            />
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="float-end me-2" type="submit" disabled={loading}>
                            {loading ? 'Loading...' : 'Save'}
                        </Button>

                        <Button className="float-end" onClick={() => onCancel()} disabled={loading}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

CameraModal.defaultProps = {
    data: {
        serialNr: undefined,
        comment: undefined,
        decommissioned: undefined,
    },
    onFinish: () => { }
}

export default CameraModal