import React from "react";

function Contact(props) {

    const v = props.contact;
    const addr = []

    if(v.address !== "" && v.address !== null) addr.push(<>{v.address}<br /></>)
    if(v.address2 !== "" && v.address2 !== null) addr.push(<>{v.address2}<br /></>)
    if(v.zip !== "" && v.city !== "" && v.zip !== null && v.city !== null) addr.push(<>{v.zip} {v.city}<br /></>)
    if(v.country !== "" && v.country !== null) addr.push(<>{v.country}<br /></>)

    return (
        <>
            <div><strong>{v.name}</strong> ({v.email}{!!v.phone ? " / " + v.phone : ""})</div>
            <div>{v.description} <span className="text-muted">(via Location)</span></div>
            <div className="mt-1">{addr.length > 0 && addr}</div>
        </>
    );
}

export default Contact;