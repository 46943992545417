import axios from 'axios';
import React from "react";
import { Container, Row, Col, Button } from 'react-bootstrap';
import NotificationModal from "../../components/NotificationModal";
import useModal from "../../utils/useModal";

import * as Constants from '../../utils/constants';
import { Helmet } from 'react-helmet';

const CustomerDelete = function (props) {

    const cId = props?.match?.params?.id;
    const [data, setData] = React.useState(null);
    const { data: notificationData, setData: setNotificationData } = useModal();

    React.useEffect(() => {
        axios.get(Constants.API_BASE + 'api/customer/' + cId)
            .then(res => {
                setData(res.data);
            }).catch(err => console.error(err))
    }, [cId])

    if (!cId) {
        console.error("Customer ID is not set.")
        return "customer id is not set";
    }

    if (data === null) {
        return "loading...";
    }

    const deleteHandler = (formData) => {
        axios.delete(Constants.API_BASE + 'api/customer/' + cId)
            .then(res => {
                props.history.push({
                    pathname: '/customers',
                    state: { toast: `Customer ${data.name} has been deleted.` }
                });

            }).catch(err => setNotificationData({ title: "Failed", text: err.message }))
    }

    return (
        <Container className="mt-5 w-25">
            <NotificationModal data={notificationData} />
            <Helmet>
                <title>{data === null ? "Loading" : data.name} - Anubis</title>
            </Helmet>
            <Row>
                <Col>
                    <h2 className="text-center">Edit Customer</h2>
                    <p className="text-center">
                        Please confirm that you would like to delete the following customer:
                    </p>
                    <p className="code text-center">
                        {data.name} (id: {data.id})
                    </p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button className="" onClick={() => props.history.push('/customers')}>Cancel</Button>
                    <Button variant="warning" className="float-end" onClick={() => deleteHandler()}>Delete</Button>
                </Col>
            </Row>

        </Container>
    );
};

export default CustomerDelete;
