import React from "react";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import stripJsonComments from 'strip-json-comments';

const ConfigEditorComponent = function (props) {

    const [showDeleteModal, setShowDeleteModal] = React.useState(false);

    const handleDeleteModalConfirmed = (formData) => {
        setShowDeleteModal(false);
        props.deleteHandler(formData);
    }
    const handleDeleteModalShow = () => setShowDeleteModal(true);

    const { register, handleSubmit, errors } = useForm();
    const onSubmit = data => props.saveHandler(data);

    const jsonValidator = (value) => {
        try {
            JSON.parse(stripJsonComments(value));
            return true;
        } catch {
            return "Cannot parse JSON. Please check for syntax errors.";
        }
    }

    return (
        <Row>
            <Col>
                <h4 style={{marginLeft: 0}}>{(props?.default?.type) ? props.default.type : "unknown"}</h4>
                {props.help}
                <Form onSubmit={handleSubmit(onSubmit)} className="mb-3 clearfix">
                    <Form.Group>
                        <Form.Label>JSON configuration:</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows="10"
                            name="json"
                            placeholder="This should be the config"
                            ref={register({ required: true, validate: jsonValidator })}
                            isInvalid={errors?.json}
                            defaultValue={props?.default?.json && props?.default?.json}
                            className="code"
                        />
                        <Form.Control.Feedback type="invalid">{errors?.json?.message}</Form.Control.Feedback>
                    </Form.Group>
                    <input type="hidden" ref={register({})} value={props?.default?.id} name="id" />
                    <input type="hidden" ref={register({})} value={props?.default?.type} name="type" />

                    <Button variant="success" className="float-end mt-1" type="submit">
                        Save {props?.default?.type}
                    </Button>
                    {props.showDelete && (
                    <Button variant="danger" className="float-end me-1 mt-1"  onClick={handleDeleteModalShow}>
                        Delete {props?.default?.type}
                    </Button>
                    )}
                </Form>

                {props.showDelete && (
                <Modal show={showDeleteModal} onHide={handleDeleteModalConfirmed}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Config: {(props?.default?.type) ? props.default.type : "unknown"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Please confirm that this configuration should be deleted.</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => setShowDeleteModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={() => handleDeleteModalConfirmed(props.default)}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
                )}
            </Col>
        </Row>
    );
};

ConfigEditorComponent.defaultProps = {
    showDelete: false,
    deleteHandler: alert,
    help: <p class="text-danger">No help specified</p>,
}

export default ConfigEditorComponent;
