import React from "react";
import axios from 'axios';
import { Container, Row, Col, Form, Alert, Button, Modal, Spinner, Table } from "react-bootstrap"
import { productionNavStates, productionNavTitle } from "../../utils/navStatesProduction";
import { SubNavigation } from "../../components";
import { useForm } from "react-hook-form";
import { generatePassword } from "../../utils/password";
import * as Constants from '../../utils/constants';
import NotificationModal from "../../components/NotificationModal";
import useModal from "../../utils/useModal";


const Invoices = () => {
  const { register, handleSubmit, errors, watch } = useForm();
  const [processModal, setProcessModal] = React.useState(null)
  const {data: notificationData, setData: setNotificationData} = useModal()

  const onSubmit = formData => {

    setProcessModal({ status: "pending" })
    formData.generation = parseInt(formData.generation);

    console.log(Constants.API_BASE + 'api/setup/createAuthFile')
    axios.post(Constants.API_BASE + 'api/setup/createAuthFile', formData)
      .then(res => {
        setProcessModal(res.data)
      }).catch(err => {
        setNotificationData({title: "Failed", text: err.message})
        setProcessModal(null)
      })
  }

  const idFragment = watch('systemId')
  const hostFragment = watch('hostname')

  const generatedPassword = generatePassword(10)

  console.log("errors", errors)
  return (
    <>
      <SubNavigation pages={productionNavStates} title={productionNavTitle} />
      <NotificationModal data={notificationData} />

      <Modal show={processModal !== null} backdrop="static" onHide={() => setProcessModal(null)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Auth File Creation</Modal.Title>
          </Modal.Header>
          {processModal === null || processModal.status === "pending" ? (
            <Modal.Body><Spinner animation="border" style={{ position: "relative", top: "25%", left: "50%" }} /></Modal.Body>
          ) : (
            <Modal.Body>
              <p>The process has finished. Information on the initial steps is listed below:</p>
              <Table size="sm" responsive>
                <tbody>
                  {processModal.log.map((v, index) => {
                    let color = "#ff0000";
                    let text = "unk";

                    switch (v.level) {
                      case "info":
                        color = Constants.COLORS.INFO
                        text = "info"
                        break;
                      case "error":
                        color = Constants.COLORS.ERROR
                        text = "error"
                        break;
                      case "warning":
                        color = Constants.COLORS.WARNING
                        text = "warning"
                        break;
                      default:
                        color = "#ff0000"
                        text = "unk"
                        break;
                    }

                    return (<tr key={"log-" + index}>
                      <td style={{ "width": 20 }}>{index + 1}</td>
                      <td style={{ "width": 50, textAlign: "center", "color": color }}>{text}</td>
                      <td>{v.message}</td>
                    </tr>)
                  })}
                </tbody>
              </Table>
              {processModal.success === true ? (
                <Alert variant="success">Creation successful.</Alert>
              ) : (
                <Alert variant="danger">Creation failed. Error analysis and potential manual data cleanup may be required.</Alert>
              )}
            </Modal.Body>
          )}
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setProcessModal(null)}>
              Close
            </Button>
            <Button variant="primary" onClick={() => setProcessModal(null)}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>


      <Container className="container-sm" size="sm">
        <Row className="mt-3 justify-content-center">
          <Col className="col-6">
            <h2 className="text-center">Create Auth File</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="col-6">
            <Form onSubmit={handleSubmit(onSubmit)}>

              <Alert className="mt-3 mb-3">
                The username is a combination of the system id and the host name. It is generated automatically: <br />
                <br />
                Current username: <strong>{idFragment + "_" + hostFragment}</strong>
              </Alert>

              <Form.Group className="mb-1">
                <Form.Label>System Id</Form.Label>
                <Form.Control type="text" name="systemId" ref={register({ required: true, minLength: 5 })} isInvalid={errors?.systemId} />
                <Form.Control.Feedback type="invalid">Please enter a system id (min length 5)</Form.Control.Feedback>
                <Form.Text className="text-muted">
                  Enter the system's ID, e.g. DEUTT01
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-1">
                <Form.Label>Hostname</Form.Label>
                <Form.Control type="text" name="hostname" placeholder="XPU-..." ref={register({ required: true, minLength: 5})} isInvalid={errors?.hostname} />
                <Form.Control.Feedback type="invalid" >Please enter a host name: (min length 5)</Form.Control.Feedback>
                <Form.Text className="text-muted">
                  Enter the system's host name, e.g. XPU-900
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-1">
                <Form.Label>Password</Form.Label>
                <Form.Control type="text" name="password" ref={register({ required: false })} isInvalid={errors?.password} placeholder="Enter a password " defaultValue={generatedPassword} />
                <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
              </Form.Group>
              <Button variant="primary" className="float-end mt-3 mb-5" type="submit">
                Create Configuration Bundle
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  )
};

export default Invoices;
