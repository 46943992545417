
import React from "react";
import { Container, Row, Col } from 'react-bootstrap'
import { GateFormComponent, ConfigComponent } from '../../components'
import GateRemoteCommands from "../../components/GateRemoteCommands";

const GateUpdate = function (props) {



    const gId = props?.match?.params?.id;
  
    let allowedConfigs = [
        {scope: "gate", type: "updatetask"}, 
        {scope: "gate", type: "sensorservice"},
        {scope: "gate", type: "mxuserprefs"}, 
        //{scope: "gate", type: "mailexport"},
        //{scope: "gate", type: "anubis"}, 
    ];

    return (
        <Container className="mt-3 pb-5">

            <GateFormComponent id={gId} header={"System Properties"} />

            <Row>
                <Col>
                    <h3 className="text-center mt-3">Configurations</h3>
                </Col>
            </Row>

            <ConfigComponent id={gId} parentType="gate" allowedConfigs={allowedConfigs} />

            <GateRemoteCommands id={gId} />

        </Container>
    );
};

export default GateUpdate;
