import axios from 'axios';
import React from "react";

import { Container } from 'react-bootstrap'
import NotificationModal from "../../components/NotificationModal";
import useModal from "../../utils/useModal";

import * as Constants from '../../utils/constants';
import TimeTrackForm from '../../components/TimeTrackForm';
import { useLocation } from 'react-router-dom';
import LoadingSpinner from '../../components/LoadingSpinner';

const TimetrackUpdate = function (props) {

    const { search } = useLocation();
    const query = new URLSearchParams(search)
    const customerId = query.get("customerId");
    const [data, setData] = React.useState(null);
    const { data: notificationData, setData: setNotificationData } = useModal();
    const recordId = props?.match?.params?.id;


    React.useEffect(() => {
        axios.get(Constants.API_BASE + 'api/timetrack/' + recordId).then(res => setData(res.data)).catch(err => console.error(err))
    }, [recordId])

    const saveHandler = async (formData) => {
        try {
            await axios.put(Constants.API_BASE + 'api/timetrack/'+recordId, formData,)

            props.history.push({
                pathname: '/customers/show/' + customerId,
                state: { toast: `Successfully updated time tracking record.` }
            });

        } catch (err) { setNotificationData({ title: "Failed", text: err.message }) }
    }

    if (data === null) {
        return <LoadingSpinner text="Loading time tracking record..." />
    }

    return (
        <Container className="mt-3">
            <NotificationModal data={notificationData} />
            <TimeTrackForm default={data} saveHandler={saveHandler} customerId={customerId} />
        </Container>
    );
};

export default TimetrackUpdate;
