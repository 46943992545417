import React from "react";
import { Container, Row, Col, Button, Alert } from "react-bootstrap"
import { Link } from "react-router-dom";
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import { productionNavStates, productionNavTitle } from "../../utils/navStatesProduction";
import { SubNavigation } from "../../components";
import * as Constants from '../../utils/constants';
import BinaryDescisionModal from "../../components/BinaryDescisionModal";
import NotificationModal from "../../components/NotificationModal";
import useModal from "../../utils/useModal";

const ProductionSheets = () => {
  const [data, setData] = React.useState(null)
  const [generate, setGenerate] = React.useState(null)
  const [toast, setToast] = React.useState(null)
  const { data: notificationData, setData: setNotificationData } = useModal();

  React.useEffect(() => {
    axios.get(Constants.API_BASE + 'api/production/tickets')
      .then(res => {

        const newData = {
          ticketBaseUrl: res.data.ticketBaseUrl,
          tickets: res.data.results.map(d => d.properties)
        }

        setData(newData);
      })
  }, [])

  const submitForGeneration = async (d) => {

    try {
      await axios.post(Constants.API_BASE + 'api/production/generateSheets', { ticketId: d.hs_object_id })
      setToast({ variant: 'success', message: `Successfully triggered production sheet creation. The documents will be created on Google Drive within the next minutes.` })
    } catch (err) {
      setNotificationData({ title: "Trigger Failed", text: err.message });
      //setToast({ variant: 'danger', message: `Failed to trigger the production sheet creation. N8N returned with an error. Please see the browser console for more information.` })
    }
    setGenerate(null)
  }


  const editButton = function (cell, row, rowIndex) {
    return (
      <>
        <Link to={{ pathname: data.ticketBaseUrl + row.hs_object_id }} size="sm" className="me-1" target='_blank' rel='noreferrer'>
          Open in Hubspot
        </Link>
        <Button size="sm" as={Link} to={"/production/createSheetPreview/" + row.hs_object_id}>
          Create Sheets
        </Button>
      </>
    )
  }

  const nameFormatter = function (cell, row, rowIndex) {
    return (
      <Link to={{ pathname: data.ticketBaseUrl + row.hs_object_id }} size="sm" target="_blank" >
        {cell}
      </Link>
    )
  }

  const columns = [
    {
      dataField: 'content',
      text: "Production Ticket",
      formatter: nameFormatter,

    }, {
      dataField: 'id',
      isDummyField: true,
      text: "Actions",
      formatter: editButton,
      align: 'right',
      headerStyle: () => {
        return { width: "250px" };
      }
    }
  ];

  return (
    <>
      <BinaryDescisionModal
        show={generate !== null}
        title="Create Production Sheets"
        text={<span>Create production sheets for this ticket?<br />Please ensure, that all data in Hubspot is set properly and final.<br /><strong>{generate?.content}</strong></span>}
        no={() => setGenerate(null)}
        yes={() => submitForGeneration(generate)}
      />
      <NotificationModal data={notificationData} />
      <SubNavigation pages={productionNavStates} title={productionNavTitle} />
      <Container>
        <h2 className="my-3 text-center">Generate Production Sheets</h2>
        <Row>
          <Col className="">
            <p>Production sheets can be created for all Orders/Deals with Service/Production tickets in the "Waiting for Production" column. By pressing the "Create Sheets" button, you will create the following documents:</p>
            <ul>
              <li>Production Sheet</li>
              <li>Packaging List</li>
              <li>Order Confirmation</li>
              <li>Bizerba Production Sheet / Scale Order</li>
            </ul>
            <p>It is possible to recreate these documents by re-running the process for tickets. <strong>Warning: this may create duplicate files on Google Drive</strong></p>

            {toast !== null && (
              <Alert variant={toast.variant} onClose={() => setToast(null)} dismissible closeLabel="Close">
                {toast.message}
              </Alert>
            )}
          </Col>
        </Row>

        <Col>
          <Row>
            {data === null ? "loading data from Hubspot..." : (

              <BootstrapTable
                keyField="hs_object_id"
                data={data.tickets}
                columns={columns}
                condensed
                bordered={false}
              />

            )}

          </Row>
        </Col>

      </Container>
    </>
  )
};

export default ProductionSheets;
