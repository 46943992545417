import { useState } from 'react';

const useModal = () => {
  const [internalData, setInternalData] = useState(null);

  function toggle(data) {
    if(data === null)
        setInternalData(null);
    else
        setInternalData({ closeFunc: () => setInternalData(null), ...data});
  }

  return {
    data: internalData,
    setData: toggle,
  }
};

export default useModal;