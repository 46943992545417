import React from "react";
import { Form, Table, InputGroup, Button } from 'react-bootstrap'
import {  useHistory } from "react-router-dom";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan } from "@fortawesome/free-solid-svg-icons";

import { API_BASE } from '../utils/constants';

import Fuse from 'fuse.js'


function SearchPanel(props) {

    const [fuse, setFuse] = React.useState(null);
    const [searchResults, setSearchResults] = React.useState([]);
    const history = useHistory();

    React.useEffect(() => {
        // Query sensor data
        axios.get(API_BASE + 'api/search/getSearchData')
            .then(res => {
                const fuseOptions = {
                    // isCaseSensitive: false,
                    // includeScore: true,
                    // shouldSort: true,
                    // includeMatches: false,
                    // findAllMatches: false,
                    minMatchCharLength: 2,
                    // location: 0,
                    threshold: 0.3,
                    // distance: 20,
                    // useExtendedSearch: false,
                    // ignoreLocation: false,
                    // ignoreFieldNorm: false,
                    // fieldNormWeight: 1,
                    keys: [
                        "systemName",
                        "locationName",
                        "customerName"
                    ]
                };

                setFuse(new Fuse(res.data, fuseOptions));
            })
            .catch(err => {
                console.error(err);
            });
    }, [])

    const navigateClearBlur = (target) => {
        setSearchResults([]);
        history.push(target);
        props.onBlur();
    }

    const handleChange = (e) => {
        const needle = e.target.value;
        setSearchResults(fuse.search(needle))
    }

    const handleClear = () => {
        setSearchResults([]);
    }

    let searchResultsJsx = "";
    if (searchResults === null || searchResults.length === 0) {
        searchResultsJsx = (
            <tr>
                <td className="text-center">
                    <span style={{ fontSize: "300%", fontWeight: 200, color: 'rgba(0, 0, 0, 0.1)', paddingTop: 10, display: 'inline-block' }}>&lt;NULL /&gt;</span>
                </td>
            </tr>
        )
    } else {
        searchResultsJsx = searchResults.map((v, index) => {
            return (
                <tr key={"search-result" + index}>
                    <td>
                        <h4><span style={{ textDecoration: "upper" }} className="text-muted">SYSTEM</span> <Button className="link" variant="link" style={{ textDecoration: 'upper', fontSize: "1rem", padding: "0", fontWeight: "600", verticalAlign: "inherit", border: "none", lineHeight: "inherit" }} onClick={() => navigateClearBlur("/systems/show/" + v.item.systemId)}>{v.item.systemName}</Button></h4>
                        <span className="indent"><Button variant="link" style={{ fontSize: "0.8rem", padding: 0, verticalAlign: "inherit", border: "none", lineHeight: "inherit" }} onClick={() => navigateClearBlur("/systems/edit/" + v.item.systemId)}>Edit system</Button></span><br />
                        <span className="indent">in <Button variant="link" style={{ fontSize: "0.8rem", padding: 0, verticalAlign: "inherit", border: "none", lineHeight: "inherit" }} onClick={() => navigateClearBlur("/locations/edit/" + v.item.locationId)}>{v.item.locationName} (edit)</Button></span><br />
                        <span className="indent">used by <Button variant="link" style={{ fontSize: "0.8rem", padding: 0, verticalAlign: "inherit", border: "none", lineHeight: "inherit" }} onClick={() => navigateClearBlur("/customers/edit/" + v.item.customerId)}>{v.item.customerName} (edit)</Button></span>
                    </td>
                </tr>
            )
        })
    }

    return (
        <div style={props.inputStyle}>
            <InputGroup>
                <Form.Control size={props.inputSize}
                    onChange={(e) => handleChange(e)} onBlur={() => props.onBlur()}
                    type="text" placeholder="Search system/location/customer"
                />
                <Button size={props.inputSize} variant="outline-secondary" onClick={() => handleClear()}>
                    <FontAwesomeIcon icon={faBan} size="sm" color="#00000088" style={{ cursor: "hand" }} />
                </Button>
            </InputGroup>

            {(props.showEmpty || searchResults?.length > 0) && (
                <Table size="sm" responsive className="searchPanel mt-2" style={props.tableStyle}>
                    <tbody>
                        {searchResultsJsx}
                    </tbody>
                </Table>
            )}
        </div>
    );
}

SearchPanel.defaultProps = {
    tableStyle: {},
    inputStyle: {},
    showEmpty: true,
    inputSize: 'lg',
    onBlur: () => { }
}

export default SearchPanel;