import React from "react";
import 'moment-timezone';
import moment from 'moment'
import axios from 'axios';
import * as Constants from '../utils/constants';
import { Row, Col, Spinner } from "react-bootstrap"

import {
    LineChart, Line, Label, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip, Legend
} from 'recharts';

const formatAsHourMinute = (tick) => {
    return moment(tick, 'X').format('HH:mm')
};

const SensorDataRow = function (props) {

    const systemId = props.systemId;
    const [sensorData, setSensorData] = React.useState(null);
    React.useEffect(() => {
        // Query sensor data
        axios.get(Constants.API_BASE + 'api/diag/sensors?gateId=' + systemId)
            .then(res => {
                setSensorData(res.data);
            })
            .catch(err => {
                console.error(err);
            });
    }, [systemId])

    const getTicks = () => {
        // Date -> round down to last full hour -> subtract 24 hours
        const hour = 60 * 60;
        const dateSec = new Date() / 1000;
        const start = dateSec - (dateSec % (60 * 60)) - (24 * hour);

        let ticks = [];
        for (var i = 1; i <= 24; i++) ticks.push(start + i * hour);
        return ticks;
    }

    const xTicks = getTicks();
    const xEnd = moment().format('X');

    return (
        <Row>
            <Col xs={12} sm={6} className="panel">
                <h3 align="center">Temperature sensors</h3>
                {(sensorData === null) ? <Spinner animation="border" style={{ position: "relative", top: "15%", left: "50%" }} /> : (

                    <ResponsiveContainer width="100%" height={props.height}>
                        <LineChart data={sensorData} margin={{ top: 5, right: 30, left: 10, bottom: 5 }}                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="ts" domain={[xEnd - (60 * 60 * 24), xEnd]} type="number" tickFormatter={formatAsHourMinute} ticks={xTicks} />
                            <YAxis type="number" domain={['dataMin - 5', 'dataMax + 5']} yAxisId="left">
                                <Label angle={-90} value="Temperature ° C" position='insideLeft' style={{ textAnchor: 'middle' }} />
                            </YAxis>

                            <Tooltip labelFormatter={formatAsHourMinute} />
                            <Legend />
                            <Line isAnimationActive={false} yAxisId="left" type="linear" name="CPU Temp." dataKey="cpuTemp" stroke={Constants.CONTRAST_COLORS[0]} strokeWidth={2} dot={false} />
                            <Line isAnimationActive={false} yAxisId="left" type="linear" name="HDD Temp." dataKey="hddTemp" stroke={Constants.CONTRAST_COLORS[1]} strokeWidth={2} dot={false} />
                            <Line isAnimationActive={false} yAxisId="left" type="linear" name="MB1 Temp." dataKey="mb1Temp" stroke={Constants.CONTRAST_COLORS[2]} strokeWidth={2} dot={false} />
                            <Line isAnimationActive={false} yAxisId="left" type="linear" name="MB2 Temp." dataKey="mb2Temp" stroke={Constants.CONTRAST_COLORS[3]} strokeWidth={2} dot={false} />
                            
                        </LineChart>
                    </ResponsiveContainer>
                )}
            </Col>
            <Col xs={12} sm={6} className="panel">
            <h3 align="center">CPU load and clock</h3>
                {(sensorData === null) ? <Spinner animation="border" style={{ position: "relative", top: "15%", left: "50%" }} /> : (

                    <ResponsiveContainer width="100%" height={props.height}>
                        <LineChart data={sensorData} margin={{ top: 5, right: 30, left: 10, bottom: 5, }} >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="ts" domain={[xEnd - (60 * 60 * 24), xEnd]} type="number" tickFormatter={formatAsHourMinute} ticks={xTicks} />
                            <YAxis type="number" domain={[0, 100]} yAxisId="left">
                                <Label angle={-90} value="Load %" position='insideLeft' style={{ textAnchor: 'middle' }} />
                            </YAxis>
                            <YAxis type="number" domain={['dataMin - 400', 'dataMax + 400']} yAxisId="right" orientation="right">
                                <Label angle={-90} value="Clock Speed MHz" position='insideRight' style={{ textAnchor: 'middle' }} />
                            </YAxis>
                            <Tooltip labelFormatter={formatAsHourMinute} />
                            <Legend />

                            <Line isAnimationActive={false} yAxisId="left" type="linear" name="CPU Load" dataKey="cpuLoad" stroke={Constants.CONTRAST_COLORS[0]} strokeWidth={2} dot={false} />
                            <Line isAnimationActive={false} yAxisId="right" type="linear" name="CPU Clock" dataKey="cpuClock" stroke={Constants.CONTRAST_COLORS[1]} strokeWidth={2} dot={false} />
                        </LineChart>
                    </ResponsiveContainer>
                )}
            </Col>
        </Row>
    );
};




SensorDataRow.defaultProps = {
    height: 250,

}

export default React.memo(SensorDataRow);