import React from "react";
import 'moment-timezone';
import moment from 'moment'
import * as Constants from '../../utils/constants';

import {
  ComposedChart, Line, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Text, Bar
} from 'recharts';

const SystemUsageChart = function (props) {

  const formatAsHourMinute = (tick) => {
    return moment(tick, 'X').format('HH:mm')
  };

  const formatTooltip = (tick) => {
    const from = moment(tick, 'X').format('DD.MM.yyyy HH:mm')
    const to = moment(tick, 'X').add(1, 'hours').format('HH:mm')
    return `From ${from} to ${to}`
  };

  const getTicks = () => {
    // Date -> round down to last full hour -> subtract 24 hours
    const hour = 60 * 60;
    const dateSec = new Date() / 1000;
    const start = dateSec - (dateSec % (60 * 60)) - (24 * hour * 7);

    let ticks = [];
    for (var i = 1; i <= 24 * 7; i++) ticks.push(start + i * hour);
    return ticks;
  }

  const xTicks = getTicks();
  const xEnd = moment().format('X');

  const extra = []

  let axIndex = 0;

  let usedData = props.data;
  let chartMargin = { top: 20, right: 30, left: 20, bottom: 5 }  
  let colorIdx = 1;

  if (props.secondData !== null) {
    
    if (usedData.length === props.secondData.length) {
      
      // merge original data into second data
      for (let i = 0; i < props.data.length; i++) props.secondData[i].cnt = props.data[i].cnt;
      usedData = props.secondData;
      chartMargin.right = 0;
      // add second axis
      extra.push(<YAxis yAxisId="right" orientation="right" key={'ax' + axIndex++} />)

      let dataKeys = Object.keys(props.secondData[0]);

      // add additional lines
      dataKeys.forEach(v => {
        if (v !== "block" && v !== "cnt") {
          //extra.push(<Line yAxisId="right" isAnimationActive={false} dataKey={v} name={"System ID: " + v} strokeWidth={1} stroke={Constants.PLOT_COLORS[colorIdx++]} dot={false} key={'ax' + axIndex++} />)
          extra.push(<Bar yAxisId="right" isAnimationActive={false} dataKey={v} name={"System ID: " + v} key={'ax' + axIndex++} fill={Constants.PLOT_COLORS[colorIdx++]} stackId="a" />)
        }
      })
    } else {
      console.log("SystemUsageChart: original and second data have different lengths!")
    }

  }


  return (
    <ResponsiveContainer width="100%" height={props.height}>
      <ComposedChart
        data={usedData}
        margin={chartMargin}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="block" tickFormatter={formatAsHourMinute} domain={[xEnd - (60 * 60 * 24), xEnd]} type="number" ticks={xTicks} />
        <YAxis yAxisId="left" label={<Text x={0} y={0} dx={40} dy={props.height / 2 + 50} offset={0} angle={-90}>Number of Measurements</Text>} />
        

        <Tooltip labelFormatter={formatTooltip} />
        <Legend />

        {extra}

        <Line yAxisId="left" isAnimationActive={false} dataKey="cnt" name="Global system usage in 1 hour intervals" stroke={Constants.PLOT_COLORS[0]} strokeWidth={2} dot={false} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

SystemUsageChart.defaultProps = {
  height: 250,
  secondData: null
}

export default SystemUsageChart;