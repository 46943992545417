import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faChartLine, faBookAtlas, faScrewdriverWrench, faRulerCombined, faHardDrive, faIndustry, faCity, faPlugCirclePlus, faDownload } from '@fortawesome/free-solid-svg-icons'
import Logo from "../assets/images/logo.png";
import SearchPanel from "./SearchPanel";

const TopBar = props => {

    React.useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });

    const isSticky = (e) => {
        const header = document.querySelector('#header');
        const scrollTop = window.scrollY;
        scrollTop >= 40 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
    };

    return (
        <>

            <Nav style={styles.topbar} activeKey="/home" onSelect={selectedKey => alert(`selected ${selectedKey}`)} id="header">

                <img src={Logo} alt="Logo" style={{ width: "32px", height: "32px", marginTop: 4, marginLeft: 4, marginBottom: 4 }} />

                <Nav.Link as={RouterNavLink} to="/" exact activeClassName="router-link-exact-active" style={styles.navLinks}>
                    <FontAwesomeIcon icon={faHome} className="nav-button" />
                    <span className="nav-text">HOME</span>
                </Nav.Link>

                <Nav.Link as={RouterNavLink} to="/logs" exact activeClassName="router-link-exact-active" style={styles.navLinks}>
                    <FontAwesomeIcon icon={faBookAtlas} className="nav-button" />
                    <span className="nav-text">LOGS</span>
                </Nav.Link>

                <Nav.Link as={RouterNavLink} to="/usage" exact activeClassName="router-link-exact-active" style={styles.navLinks}>
                    <FontAwesomeIcon icon={faChartLine} className="nav-button" />
                    <span className="nav-text">USAGE</span>
                </Nav.Link>

                <Nav.Link as={RouterNavLink} to="/maintenance" exact activeClassName="router-link-exact-active" style={styles.navLinks}>
                    <FontAwesomeIcon icon={faScrewdriverWrench} className="nav-button" />
                    <span className="nav-text">MAINTENANCE</span>
                </Nav.Link>

                <Nav.Link as={RouterNavLink} to="/systems" exact activeClassName="router-link-exact-active" style={styles.navLinks}>
                    <FontAwesomeIcon icon={faRulerCombined} className="nav-button" />
                    <span className="nav-text">SYSTEMS</span>
                </Nav.Link>

                <Nav.Link as={RouterNavLink} to="/xpus" exact activeClassName="router-link-exact-active" style={styles.navLinks}>
                    <FontAwesomeIcon icon={faHardDrive} className="nav-button" />
                    <span className="nav-text">XPUS</span>
                </Nav.Link>

                <Nav.Link as={RouterNavLink} to="/customers" exact activeClassName="router-link-exact-active" style={styles.navLinks}>
                    <FontAwesomeIcon icon={faIndustry} className="nav-button" />
                    <span className="nav-text">CUSTOMERS</span>
                </Nav.Link>

                <Nav.Link as={RouterNavLink} to="/locations" exact activeClassName="router-link-exact-active" style={styles.navLinks}>
                    <FontAwesomeIcon icon={faCity} className="nav-button" />
                    <span className="nav-text">LOCATIONS</span>
                </Nav.Link>

                <Nav.Link as={RouterNavLink} to="/production/initSystem" exact activeClassName="router-link-exact-active" style={styles.navLinks}>
                    <FontAwesomeIcon icon={faPlugCirclePlus} className="nav-button" />
                    <span className="nav-text">PROD</span>
                </Nav.Link>

                <Nav.Link as={RouterNavLink} to="/downloads" exact activeClassName="router-link-exact-active" style={styles.navLinks}>
                    <FontAwesomeIcon icon={faDownload} className="nav-button" />
                    <span className="nav-text">UPDATES</span>
                </Nav.Link>
                {/*
                <Nav.Link as={RouterNavLink} to="/profile" exact activeClassName="router-link-exact-active" style={styles.navLinks}>
                    <OverlayTrigger overlay={<Tooltip>Profile (Debug)</Tooltip>} placement="right">
                        <User  className="nav-button" />
                    </OverlayTrigger>
                </Nav.Link>
                */}
                <Nav.Link as={RouterNavLink} to="/profile" exact activeClassName="router-link-exact-active" style={styles.navLinks}>
                    <span className="nav-text">LOGOUT</span>
                </Nav.Link>

                <div id="search-results" style={{ display: "inline", height: 40 }}>
                    <SearchPanel inputSize="sm" showEmpty={false} inputStyle={{marginTop: 4}} tableStyle={{ position: "relative", zIndex: 800, top: 1, left: 0, display: "block", background: '#c4cacc', border: "3px solid #b3b8b9", borderTop: null, padding: 8}} />
                </div>


            </Nav>
        </>
    );
};
export default TopBar

const styles = {
    navLinks: {
        textAlign: "center",
        color: "#545c5e",
        
    }
}