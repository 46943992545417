import axios from 'axios';
import React from "react";
import { Row, Col, Button, Modal, Alert } from 'react-bootstrap'
import { ConfigEditorComponent } from './'
import * as Constants from '../utils/constants';
import NotificationModal from "./NotificationModal";
import useModal from "../utils/useModal";


const ConfigComponent = function (props) {

    const parentId = props.id
    const parentType = props.parentType;
    const parentQueryKey = (parentType === "gate") ? "gateId" : "customerId";
    const parentString = (parentType === "gate") ? "gate" : "customer";
    const allowedConfigs = props.allowedConfigs;

    const [configs, setConfigs] = React.useState(null);
    const [showModal, setShowModal] = React.useState(false);
    const [alteredConfigs, setAlteredConfigs] = React.useState(0);
    const [toast, setToast] = React.useState(null)
    const {data: notificationData, setData: setNotificationData} = useModal();

    React.useEffect(() => {
        axios.get(Constants.API_BASE + 'api/serializedconfig', {
            params: {
                [parentQueryKey]: parentId
            }
        })
            .then(res => setConfigs(res.data))
            .catch(err => console.error(err))
    }, [parentId, parentQueryKey, alteredConfigs])

    if (!parentId) {
        console.error("Parent ID is not set.")
        return "parent id is not set";
    }

    let toastJsx = "";
    if (toast !== null) {
        toastJsx = (<Alert variant={toast.variant} onClose={() => setToast(null)} dismissible>{toast.message}</Alert>)
    }

    const deleteConfigHandler = (formData) => {
        axios.delete(Constants.API_BASE + 'api/serializedconfig/' + formData.id)
            .then(res => {
                setToast({type: formData.type, variant: 'success', message: `Deleted '${formData.type}' from ${parentString} '${formData[parentQueryKey]}'.` })
                setAlteredConfigs(alteredConfigs + 1);
            }).catch(err => {
                setNotificationData({title: "Failed", text:  `Failed removing '${formData.type}' from ${parentString} '${formData[parentQueryKey]}': ${err.message}`});
            })
    }

    const createConfigHandler = (type, scope) => {
        setShowModal(true);

        axios.post(Constants.API_BASE + 'api/serializedconfig/', {
            type: type,
            scope: scope,
            [parentQueryKey]: parentId,
            json: "{}"
        })
        .then(res => {
            setShowModal(false);
            setAlteredConfigs(alteredConfigs + 1);
        }).catch(err => {
            setNotificationData({title: "Creation Failed", text: err.message});
            setShowModal(false);
        })
    }

    const updateConfigHandler = (formData) => {
        console.log(formData)
        axios.put(Constants.API_BASE + 'api/serializedconfig/' + formData.id, { json: formData.json })
            .then(res => {
                setToast({type: formData.type, variant: 'success', message: `Saved configuration.` });
                setAlteredConfigs(alteredConfigs + 1);

            }).catch(err => {
                console.error(err)
                setNotificationData({title: "Update Failed", text: err.message});
            })

    }

    // Create a dict of help texts
    const help = {
        updatetask: (<><strong>UpdateTask Parameters: </strong>
            The update task uses default parameters which can be overwritten by a remote configuration. The update task itself does not have a local 
            configuration. System-level parameters completely replace customer-level parameters (no merging).</>),
        sensorservice: (<><strong>SensorService Parameters: </strong>
            The sensor service uses default parameters which can be overwritten by a remote configuration. The sensor service itself does not have a local 
            configuration. SensorService parameters for customer- and system level are merged (with js: lodash.merge).</>),
        mailexport: (<><strong>MailExport V1 Configuration: </strong>
            Settings <span className="text-danger">overwrite</span> the instance's configuration file.
            The MailExport's task definitions will only be updated if <span style={{display: "inline", fontWeight: "bold"}}>-t</span> is added to the arguments.
            System-level parameters completely replace customer-level parameters (no merging).
            </>),
        anubis: (<><strong><span style={{color: 'red'}}>*DEPRECATED*</span> Anubis Parameters: </strong>
            The general priority for settings are as follows: system-level &gt; customer-level &gt; local system.
            However, this behavior might be different depending on the target application. The Anubis instance merges the instance's parameters with
            the remote parameters to obtain a complete set of parameters. During merging, remote parameters have priority.
            </>),
        mxuserprefs: (<><strong>MetriX1 UserPreferences: </strong>
            Setting user preferences here effectively <span className="text-danger">overwrites</span> the instance's `User Preferences.json` file.
            No parameter definitions are shared between system and customer levels. The gate level definition overwrites all customer settings.
            </>),
    }


    // Create dict that indicates which configurations exist
    let possibleConfigs = {};
    allowedConfigs.forEach(val => possibleConfigs[val.type] = false);

    // Iteratively build JSX
    let configEditorsJsx = [];

    if (configs !== null) {
        // Create editors for existing configurations
        configs.forEach((c, i) => {
            if(toast !== null && toast.type === c.type) {
                configEditorsJsx.push(<Row key={"editor-"+i}>
                    <Col>{toastJsx}</Col>
                </Row>)
            }
                
            possibleConfigs[c.type] = true;
            configEditorsJsx.push(<ConfigEditorComponent saveHandler={updateConfigHandler} default={c} key={c.type} help={help[c.type]} showDelete deleteHandler={deleteConfigHandler} />)
        });

        let buttonIds = 0
        // Create buttons to create non-existing configurations
        for (const [configType, isSet] of Object.entries(possibleConfigs)) {
            if (!isSet) {
                configEditorsJsx.push((
                    <div key={"buttons-"+(buttonIds++)}>
                        {!!toastJsx.type === configType && (
                        <Row>
                            <Col>{toastJsx}</Col>
                        </Row>
                        )}
                        <Row key={configType}>
                            <Col>
                                <h4 style={{marginLeft: 0}}>{configType}</h4>
                                <p>
                                    {help[configType]}
                                </p>
                                <p className="text-danger">No configuration of type '{configType}' found</p>
                            </Col>
                            <Col xs={2}>
                                <Button className="float-end" onClick={() => createConfigHandler(configType, parentType)}>Create {configType}</Button>
                            </Col>
                        </Row>
                    </div>
                )
                )
            }
        }
    }

    return (
        <>
            {configEditorsJsx}
            <NotificationModal data={notificationData} />
            <Modal show={showModal}>
                <Modal.Header>
                    <Modal.Title>Creating Configuration</Modal.Title>
                </Modal.Header>
                <Modal.Body>Please wait...</Modal.Body>
            </Modal>
        </>
    );
};

export default ConfigComponent;
