import React from "react";
import axios from 'axios';
import { Spinner } from "react-bootstrap";

import * as Constants from '../utils/constants';
import { StatefulSubNavigation } from "../components";
import MaintenanceHealthMetrics from "../components/maintenance/MaintenanceHealthMetrics";
import MaintenanceRemoteConfiguration from "../components/maintenance/MaintenanceRemoteConfiguration";
import MaintenanceRemoteVersions from "../components/maintenance/MaintenanceRemoteVersions";
import MaintenanceRemoteEffort from "../components/maintenance/MaintenanceRemoteEffort";
import MaintenanceServiceExpiration from "../components/maintenance/MaintenanceServiceExpiration";


const pages = [
  { key: "health-metrics", title: "Health Metrics" },
  { key: "versions", title: "Deployed Versions" },
  { key: "remote-conf", title: "Remote Configuration" },
  { key: "effort", title: "Maintenance Effort" },
  { key: "service", title: "Service Expiration" },
];

const Maintenance = () => {

  const [plotData, setPlotData] = React.useState(null);
  const [view, setView] = React.useState("health-metrics")

  const query = React.useCallback(() => {
    const cancelToken = axios.CancelToken.source()

    axios.get(Constants.API_BASE + 'api/statistics/maintenanceData', { cancelToken: cancelToken.token })
      .then((result) => {
        setPlotData(result.data);
      })
      .catch(err => console.error(err))

    return () => {
      cancelToken.cancel("callback/effect cleanup.")
    }
  }, []);

  // Use effect to query for data
  React.useEffect(() => {
    query();
  }, [query])

  let jsxContent = ""
  if (plotData != null) {
    if (view === "health-metrics") {
      jsxContent = (<MaintenanceHealthMetrics data={plotData} />)
    } else if (view === "versions") {
      jsxContent = (<MaintenanceRemoteVersions data={plotData} />)
    } else if (view === "remote-conf") {
      jsxContent = (<MaintenanceRemoteConfiguration data={plotData} />)
    } else if (view === "effort") {
      jsxContent = (<MaintenanceRemoteEffort data={plotData} />)
    } else if (view === "service") {
      jsxContent = (<MaintenanceServiceExpiration data={plotData} />)
    }
  }


  return (
    <>
      <StatefulSubNavigation pages={pages} view={view} setView={setView} />

      {plotData === null ? (
        <Spinner animation="border" style={{ position: "relative", top: "100px", left: "50%" }} />
      ) : (
        <>{jsxContent}</>
      )}
    </>
  );
};

export default Maintenance;
