import axios from 'axios';
import React from "react";
import { Button, Container, Row, Col } from 'react-bootstrap'
import * as Constants from '../../utils/constants';
import { Helmet } from 'react-helmet';

const GateDelete = function (props) {

    const gId = props?.match?.params?.id;
    const [gateData, setGateData] = React.useState(null);

    React.useEffect(() => {
        axios.get(Constants.API_BASE + 'api/gate/' + gId)
            .then(res => {
                setGateData(res.data);
            }).catch(err => console.error(err))
    }, [gId])

    if (!gId) {
        console.error("Gate ID is not set.")
        return "gate id is not set";
    }

    if (gateData === null) {
        return "loading...";
    }

    const deleteHandler = (formData) => {
        axios.delete(Constants.API_BASE + 'api/gate/' + gId)
            .then(res => {
                props.history.push({
                    pathname: '/systems',
                    state: { toast: `System ${gateData.gateId} has queued for deletion. This may take a couple of minutes.` }
                });
            }).catch(err => console.error(err))
    }

    return (
        <Container className="mt-5 w-25">
            <Helmet>
                <title>{gateData === null ? "Loading" : gateData.gateId} - Anubis</title>
            </Helmet>
            <Row className="justify-content-md-center">
                <Col>
                    <h2 className="text-center">Delete System</h2>
                    <p className="text-center">
                        Please confirm that you would like to delete the following system:
                    </p>
                    <p className="code text-center">
                        {gateData.gateId} (id: {gateData.id})
                    </p>
                    <p className="text-center">
                        All data regarding this gate's <strong>messages, events, calibration, sensors</strong> will be deleted from the database.<br />
                        It cannot be recovered!<br />
                        Calibration files on the system will not be touched.
                    </p>
                </Col>
            </Row>
            <Row className="justify-content-sm-center">
                <Col>
                    <Button className="" onClick={() => props.history.push('/systems')}>Cancel</Button>
                    <Button variant="warning" className="float-end" onClick={() => deleteHandler()}>Delete</Button>
                </Col>
            </Row>

        </Container>
    );
};

export default GateDelete;
