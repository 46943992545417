import React from "react";
import { Route } from "react-router-dom";
import { useKeycloak } from '../utils/keycloak-hook'
import ErrorPage from "../views/ErrorPage";

const PrivateRoute = ({ component, role, ...args }) => {

  const { authenticated, keycloak } = useKeycloak();
  if (authenticated === false) {
    keycloak.login();
    return
  }

  const userRoles = keycloak.resourceAccess["logserver-backend"].roles;

  console.log("Required:", role, "allowed:", userRoles.includes(role))

  if(userRoles.includes(role))
    return <Route component={component} {...args} />
  else
    return <Route><ErrorPage requiredRole={role} /></Route>
};

PrivateRoute.defaultProps = {
  role: 'logserver-backend-user'
}

export default PrivateRoute;
