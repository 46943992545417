import React from "react";
import { Col, Row } from "react-bootstrap";


const UnknownServiceWarning = () => {

  return (
    <Row>
    <Col style={{backgroundColor: "#f5b800"}}>
      <h2 style={{textAlign: "center", paddingTop: 40, paddingBottom: 30, color: "#fff"}}>System may be out of Service</h2>
    </Col>
  </Row>

  );
};

export default UnknownServiceWarning;
