import React from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import { Col, Row, Button, Container, Alert } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import * as Constants from '../../utils/constants';
import { Helmet } from "react-helmet";

const Downloads = (props) => {
  const [data, setData] = React.useState([])
  const [toastDismissed, setToastDismissed] = React.useState(false)

  let toast = "";
  if (!!props.location.state?.toast && toastDismissed === false) {
    toast = (<Alert variant="success" onClose={() => setToastDismissed(true)} dismissible>{props.location.state.toast}</Alert>)
  }

  React.useEffect(() => {
    axios.get(Constants.API_BASE + 'api/download')
      .then(res => {
        console.log(res.data)
        setData(res.data);
      })
  }, [])

  const editButton = function (cell, row, rowIndex) {
    return (
      <>
        <Button as={Link} to={"/downloads/edit/" + row.app} size="sm" className="me-1">
          Edit
        </Button>
      </>
    )
  }

  const nameFormatter = function (cell, row, rowIndex) {
    return (
      <Link to={"/downloads/edit/" + row.app} size="sm">
        {cell}
      </Link>
    )
  }

  const columns = [
    {
      dataField: 'app',
      formatter: nameFormatter,
      text: "App",
    },
    {
      dataField: 'type',
      text: "Type",
    },
    {
      dataField: 'version',
      text: "Version",
    }, {
      dataField: 'checksum',
      isDummyField: true,
      text: "Actions",
      formatter: editButton,
      align: 'right',
      headerStyle: () => {
        return { width: "80px" };
      }
    }
  ];

  return (
    <Container>
      <h2 className="my-3 text-center">Software Updates</h2>
      <Helmet>
        <title>Software Updates - Anubis</title>
      </Helmet>
      {!!toast && (
        <Row>
          <Col>{toast}</Col>
        </Row>
      )}
      <Row>
        <Col>
          <p className="text-center">The log server provides software updates directly and indirectly via Google Drive. Which versions are provided is defined on this page.</p>
        </Col>
      </Row>
      <Row>
        <Col>
        <BootstrapTable
          keyField="checksum"
          data={data}
          columns={columns}
          classes="log-table"
          condensed
          bordered={false}
        />
      </Col>
      </Row>


    </Container>
  )
};

export default Downloads;
