
import React from "react";
import axios from 'axios';

import { Container, Row, Col, ListGroup, Button } from 'react-bootstrap'
import Moment from 'react-moment'
import { Link } from "react-router-dom";
import * as Constants from '../../utils/constants';
import { Helmet } from "react-helmet";

const GateDocumentations = function (props) {

    const gId = props?.match?.params?.id;
    const [gateData, setGateData] = React.useState(null);
    const [docData, setDocData] = React.useState(null);


    React.useEffect(() => {
        axios.get(Constants.API_BASE + 'api/gate/' + gId)
            .then(res => {
                setGateData(res.data);
            }).catch(err => console.error(err))

        axios.get(Constants.API_BASE + 'api/documentation?GateId=' + gId)
            .then(res => {
                setDocData(res.data);
            }).catch(err => console.error(err))
    }, [gId])

    if (gateData === null || docData === null) {
        return "Loading..."
    }

    return (
        <Container className="mt-3">
            <Helmet>
                <title>{gateData === null ? "Loading" : gateData.gateId} - Anubis</title>
            </Helmet>
            <Row>
                <Col>
                    <h2 className="text-center">Documentation for {gateData.gateId}</h2>
                    <Button as={Link} to={"/systems/show/" + gId} className="float-end clearfix mb-3">
                        Show System
                    </Button>
                </Col>
            </Row>

            <Row>
                <Col>
                    {docData.length === 0 ? (
                        <p className="text-center mt-5">There are no documentations for this system.</p>
                    ) : (
                        docData.map((v, i) => <DocumentationItem data={v} key={"doc" + i} />)
                    )}


                </Col>
            </Row>

        </Container>
    );
};

const preStyle = {
    marginTop: 2,
    backgroundColor: '#ebeff0',
}

const DocumentationItem = (props) => {

    let d = props.data.formJson.replaceAll("\\\"", "\"")
    d = d.substring(1, d.length - 1)

    try {
        d = JSON.parse(d)
    } catch {
        d = JSON.parse("[" + d + "]")
    }


    return <ListGroup as="ol" numbered>
        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start flex-column mb-2">
            <div className="flex-direction-row d-flex align-items-center justify-content-between" style={{ width: '100%' }}>
                <strong>{props.data.formType}</strong>
                <div>
                    <Moment local format="DD.MM.YYYY HH:mm:ss">{props.data.createdAt}</Moment>
                </div>
            </div>
            <div style={preStyle} className="rounded p-2 col">
                <pre >{JSON.stringify(d, null, 2)}</pre>
            </div>

        </ListGroup.Item>

    </ListGroup>
}

export default GateDocumentations;
