import React from "react";
import axios from 'axios';
import { Container, Row, Col, Spinner, Table } from "react-bootstrap"
import { Link } from "react-router-dom";
import Moment from 'react-moment';

import * as Constants from '../utils/constants';
import SearchPanel from "../components/SearchPanel";
import NumberMessages24hPanel from "../components/NumberMessages24hPanel";
import HomeTimetrackingPanel from "../components/HomeTimetrackingPanel";

const Home = function (props) {

  const [plotData, setPlotData] = React.useState({ numberMessagesData: null, lastSeenData: null });


  const query = React.useCallback(() => {

    const CancelToken = axios.CancelToken;
    const cancelToken1 = CancelToken.source()
    const cancelToken2 = CancelToken.source()


    const numberEventPromise = axios.get(Constants.API_BASE + 'api/diag/numberEvents', { cancelToken: cancelToken1.token });
    const lastSeenPromise = axios.get(Constants.API_BASE + 'api/diag/lastSeen', { cancelToken: cancelToken2.token });

    Promise.allSettled([numberEventPromise, lastSeenPromise])
      .then((results) => {
        const numberEvents = results[0];
        const lastSeen = results[1];

        const newPlotData = {
          numberMessagesData: null,
          lastSeenData: null
        }

        if (numberEvents.status === "fulfilled") {
          newPlotData.numberMessagesData = numberEvents.value.data;
        } else {
          console.error(numberEvents.reason);
        }

        if (lastSeen.status === "fulfilled") {
          newPlotData.lastSeenData = lastSeen.value.data;
        } else {
          console.error(lastSeen.reason);
        }

        setPlotData(newPlotData);
      })

    return () => {
      cancelToken1.cancel("effect cleanup. cancel token 1")
      cancelToken2.cancel("effect cleanup. cancel token 2")
    }
  }, []);

  React.useEffect(() => {
    query();
  }, [query])

  let lastSeenTable = "";
  if (plotData.lastSeenData !== null) {

    const colors = ['', '🟩', '🟨', '🟧', '🟥'];

    lastSeenTable = (
      <div style={{ maxHeight: "300px", overflowY: "scroll" }}>
        <Table size="sm" responsive>
          <thead>
            <tr>
              <th>System</th>
              <th>Last Message</th>
              <th>Difference</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {plotData.lastSeenData.map((v, index) => {
              return (<tr key={"lastSeen" + index}>
                <td><Link to={"/systems/show/" + v.id}>{v.gateId}</Link></td>
                <td>{(v.lastMessage === null) ? "unknown" : <Moment local format="DD.MM.YYYY HH:mm:ss">{v.lastMessage}</Moment>}</td>
                <td>{v.diff}</td>
                <td align="center">{colors[v.ord]}</td>
              </tr>)
            })}
          </tbody>
        </Table>
      </div>
    );
  } else {
    lastSeenTable = (<Spinner animation="border" style={{ position: "relative", top: "25%", left: "50%" }} />)
  }

  return (
    <Container fluid style={{ marginTop: "8px", marginBottom: "8px" }}>
      <Row >
        <Col xs={12} sm={4} className="panel">
          <h3>Last Received Message</h3>
          {lastSeenTable}
        </Col>

        <NumberMessages24hPanel />
      </Row>
      <Row>
        <Col xs={12} sm={4} className="panel">
          <h3>SEARCH</h3>
          <SearchPanel />
        </Col>
        <Col className="panel">
          <h3>Add Time Tracking Record</h3>
          <HomeTimetrackingPanel />
        </Col>
        <Col>{/* we need this to align panel boundaries with each other, for whatever reason */}</Col>
      </Row>
    </Container>
  );

}
export default Home;
